body[data-aos-delay="3050"][data-aos],
[data-aos][data-aos][data-aos-delay="3050"] {
  transition-delay: 3050ms;
}
body[data-aos-delay="3100"][data-aos],
[data-aos][data-aos][data-aos-delay="3100"] {
  transition-delay: 3100ms;
}
body[data-aos-delay="3150"][data-aos],
[data-aos][data-aos][data-aos-delay="3150"] {
  transition-delay: 3150ms;
}
body[data-aos-delay="3200"][data-aos],
[data-aos][data-aos][data-aos-delay="3200"] {
  transition-delay: 3200ms;
}
body[data-aos-delay="3250"][data-aos],
[data-aos][data-aos][data-aos-delay="3250"] {
  transition-delay: 3250ms;
}
body[data-aos-delay="3300"][data-aos],
[data-aos][data-aos][data-aos-delay="3300"] {
  transition-delay: 3300ms;
}
body[data-aos-delay="3350"][data-aos],
[data-aos][data-aos][data-aos-delay="3350"] {
  transition-delay: 3350ms;
}
body[data-aos-delay="3400"][data-aos],
[data-aos][data-aos][data-aos-delay="3400"] {
  transition-delay: 3400ms;
}
body[data-aos-delay="3450"][data-aos],
[data-aos][data-aos][data-aos-delay="3450"] {
  transition-delay: 3450ms;
}
body[data-aos-delay="3500"][data-aos],
[data-aos][data-aos][data-aos-delay="3500"] {
  transition-delay: 3500ms;
}
body[data-aos-delay="3550"][data-aos],
[data-aos][data-aos][data-aos-delay="3550"] {
  transition-delay: 3550ms;
}
body[data-aos-delay="3600"][data-aos],
[data-aos][data-aos][data-aos-delay="3600"] {
  transition-delay: 3600ms;
}
body[data-aos-delay="3650"][data-aos],
[data-aos][data-aos][data-aos-delay="3650"] {
  transition-delay: 3650ms;
}
body[data-aos-delay="3700"][data-aos],
[data-aos][data-aos][data-aos-delay="3700"] {
  transition-delay: 3700ms;
}
body[data-aos-delay="3750"][data-aos],
[data-aos][data-aos][data-aos-delay="3750"] {
  transition-delay: 3750ms;
}
body[data-aos-delay="3800"][data-aos],
[data-aos][data-aos][data-aos-delay="3800"] {
  transition-delay: 3800ms;
}
body[data-aos-delay="3850"][data-aos],
[data-aos][data-aos][data-aos-delay="3850"] {
  transition-delay: 3850ms;
}
body[data-aos-delay="3900"][data-aos],
[data-aos][data-aos][data-aos-delay="3900"] {
  transition-delay: 3900ms;
}
body[data-aos-delay="3950"][data-aos],
[data-aos][data-aos][data-aos-delay="3950"] {
  transition-delay: 3950ms;
}
body[data-aos-delay="4000"][data-aos],
[data-aos][data-aos][data-aos-delay="4000"] {
  transition-delay: 4000ms;
}
body[data-aos-delay="4050"][data-aos],
[data-aos][data-aos][data-aos-delay="4050"] {
  transition-delay: 4050ms;
}
body[data-aos-delay="4100"][data-aos],
[data-aos][data-aos][data-aos-delay="4100"] {
  transition-delay: 4100ms;
}
body[data-aos-delay="4150"][data-aos],
[data-aos][data-aos][data-aos-delay="4150"] {
  transition-delay: 4150ms;
}
body[data-aos-delay="4200"][data-aos],
[data-aos][data-aos][data-aos-delay="4200"] {
  transition-delay: 4200ms;
}
body[data-aos-delay="4250"][data-aos],
[data-aos][data-aos][data-aos-delay="4250"] {
  transition-delay: 4250ms;
}
body[data-aos-delay="4300"][data-aos],
[data-aos][data-aos][data-aos-delay="4300"] {
  transition-delay: 4300ms;
}
body[data-aos-delay="4350"][data-aos],
[data-aos][data-aos][data-aos-delay="4350"] {
  transition-delay: 4350ms;
}
body[data-aos-delay="4400"][data-aos],
[data-aos][data-aos][data-aos-delay="4400"] {
  transition-delay: 4400ms;
}
body[data-aos-delay="4450"][data-aos],
[data-aos][data-aos][data-aos-delay="4450"] {
  transition-delay: 4450ms;
}
body[data-aos-delay="4500"][data-aos],
[data-aos][data-aos][data-aos-delay="4500"] {
  transition-delay: 4500ms;
}
body[data-aos-delay="4550"][data-aos],
[data-aos][data-aos][data-aos-delay="4550"] {
  transition-delay: 4550ms;
}
body[data-aos-delay="4600"][data-aos],
[data-aos][data-aos][data-aos-delay="4600"] {
  transition-delay: 4600ms;
}
body[data-aos-delay="4650"][data-aos],
[data-aos][data-aos][data-aos-delay="4650"] {
  transition-delay: 4650ms;
}
body[data-aos-delay="4700"][data-aos],
[data-aos][data-aos][data-aos-delay="4700"] {
  transition-delay: 4700ms;
}
body[data-aos-delay="4750"][data-aos],
[data-aos][data-aos][data-aos-delay="4750"] {
  transition-delay: 4750ms;
}
body[data-aos-delay="4800"][data-aos],
[data-aos][data-aos][data-aos-delay="4800"] {
  transition-delay: 4800ms;
}
body[data-aos-delay="4850"][data-aos],
[data-aos][data-aos][data-aos-delay="4850"] {
  transition-delay: 4850ms;
}
body[data-aos-delay="4900"][data-aos],
[data-aos][data-aos][data-aos-delay="4900"] {
  transition-delay: 4900ms;
}
body[data-aos-delay="4950"][data-aos],
[data-aos][data-aos][data-aos-delay="4950"] {
  transition-delay: 4950ms;
}
body[data-aos-delay="5000"][data-aos],
[data-aos][data-aos][data-aos-delay="5000"] {
  transition-delay: 5000ms;
}
body[data-aos-delay="5050"][data-aos],
[data-aos][data-aos][data-aos-delay="5050"] {
  transition-delay: 5050ms;
}
body[data-aos-delay="5100"][data-aos],
[data-aos][data-aos][data-aos-delay="5100"] {
  transition-delay: 5100ms;
}
body[data-aos-delay="5150"][data-aos],
[data-aos][data-aos][data-aos-delay="5150"] {
  transition-delay: 5150ms;
}
body[data-aos-delay="5200"][data-aos],
[data-aos][data-aos][data-aos-delay="5200"] {
  transition-delay: 5200ms;
}
body[data-aos-delay="5250"][data-aos],
[data-aos][data-aos][data-aos-delay="5250"] {
  transition-delay: 5250ms;
}
body[data-aos-delay="5300"][data-aos],
[data-aos][data-aos][data-aos-delay="5300"] {
  transition-delay: 5300ms;
}
body[data-aos-delay="5350"][data-aos],
[data-aos][data-aos][data-aos-delay="5350"] {
  transition-delay: 5350ms;
}
body[data-aos-delay="5400"][data-aos],
[data-aos][data-aos][data-aos-delay="5400"] {
  transition-delay: 5400ms;
}
body[data-aos-delay="5450"][data-aos],
[data-aos][data-aos][data-aos-delay="5450"] {
  transition-delay: 5450ms;
}
body[data-aos-delay="5500"][data-aos],
[data-aos][data-aos][data-aos-delay="5500"] {
  transition-delay: 5500ms;
}
body[data-aos-delay="5550"][data-aos],
[data-aos][data-aos][data-aos-delay="5550"] {
  transition-delay: 5550ms;
}
body[data-aos-delay="5600"][data-aos],
[data-aos][data-aos][data-aos-delay="5600"] {
  transition-delay: 5600ms;
}
body[data-aos-delay="5650"][data-aos],
[data-aos][data-aos][data-aos-delay="5650"] {
  transition-delay: 5650ms;
}
body[data-aos-delay="5700"][data-aos],
[data-aos][data-aos][data-aos-delay="5700"] {
  transition-delay: 5700ms;
}
body[data-aos-delay="5750"][data-aos],
[data-aos][data-aos][data-aos-delay="5750"] {
  transition-delay: 5750ms;
}
body[data-aos-delay="5800"][data-aos],
[data-aos][data-aos][data-aos-delay="5800"] {
  transition-delay: 5800ms;
}
body[data-aos-delay="5850"][data-aos],
[data-aos][data-aos][data-aos-delay="5850"] {
  transition-delay: 5850ms;
}
body[data-aos-delay="5900"][data-aos],
[data-aos][data-aos][data-aos-delay="5900"] {
  transition-delay: 5900ms;
}
body[data-aos-delay="5950"][data-aos],
[data-aos][data-aos][data-aos-delay="5950"] {
  transition-delay: 5950ms;
}
body[data-aos-delay="6000"][data-aos],
[data-aos][data-aos][data-aos-delay="6000"] {
  transition-delay: 6000ms;
}
body[data-aos-delay="6050"][data-aos],
[data-aos][data-aos][data-aos-delay="6050"] {
  transition-delay: 6050ms;
}
body[data-aos-delay="6100"][data-aos],
[data-aos][data-aos][data-aos-delay="6100"] {
  transition-delay: 6100ms;
}
body[data-aos-delay="6150"][data-aos],
[data-aos][data-aos][data-aos-delay="6150"] {
  transition-delay: 6150ms;
}
body[data-aos-delay="6200"][data-aos],
[data-aos][data-aos][data-aos-delay="6200"] {
  transition-delay: 6200ms;
}
body[data-aos-delay="6250"][data-aos],
[data-aos][data-aos][data-aos-delay="6250"] {
  transition-delay: 6250ms;
}
body[data-aos-delay="6300"][data-aos],
[data-aos][data-aos][data-aos-delay="6300"] {
  transition-delay: 6300ms;
}
body[data-aos-delay="6350"][data-aos],
[data-aos][data-aos][data-aos-delay="6350"] {
  transition-delay: 6350ms;
}
body[data-aos-delay="6400"][data-aos],
[data-aos][data-aos][data-aos-delay="6400"] {
  transition-delay: 6400ms;
}
body[data-aos-delay="6450"][data-aos],
[data-aos][data-aos][data-aos-delay="6450"] {
  transition-delay: 6450ms;
}
body[data-aos-delay="6500"][data-aos],
[data-aos][data-aos][data-aos-delay="6500"] {
  transition-delay: 6500ms;
}
body[data-aos-delay="6550"][data-aos],
[data-aos][data-aos][data-aos-delay="6550"] {
  transition-delay: 6550ms;
}
body[data-aos-delay="6600"][data-aos],
[data-aos][data-aos][data-aos-delay="6600"] {
  transition-delay: 6600ms;
}
body[data-aos-delay="6650"][data-aos],
[data-aos][data-aos][data-aos-delay="6650"] {
  transition-delay: 6650ms;
}
body[data-aos-delay="6700"][data-aos],
[data-aos][data-aos][data-aos-delay="6700"] {
  transition-delay: 6700ms;
}
body[data-aos-delay="6750"][data-aos],
[data-aos][data-aos][data-aos-delay="6750"] {
  transition-delay: 6750ms;
}
body[data-aos-delay="6800"][data-aos],
[data-aos][data-aos][data-aos-delay="6800"] {
  transition-delay: 6800ms;
}
body[data-aos-delay="6850"][data-aos],
[data-aos][data-aos][data-aos-delay="6850"] {
  transition-delay: 6850ms;
}
body[data-aos-delay="6900"][data-aos],
[data-aos][data-aos][data-aos-delay="6900"] {
  transition-delay: 6900ms;
}
body[data-aos-delay="6950"][data-aos],
[data-aos][data-aos][data-aos-delay="6950"] {
  transition-delay: 6950ms;
}
body[data-aos-delay="7000"][data-aos],
[data-aos][data-aos][data-aos-delay="7000"] {
  transition-delay: 7000ms;
}
body[data-aos-delay="7050"][data-aos],
[data-aos][data-aos][data-aos-delay="7050"] {
  transition-delay: 7050ms;
}
body[data-aos-delay="7100"][data-aos],
[data-aos][data-aos][data-aos-delay="7100"] {
  transition-delay: 7100ms;
}
body[data-aos-delay="7150"][data-aos],
[data-aos][data-aos][data-aos-delay="7150"] {
  transition-delay: 7150ms;
}
body[data-aos-delay="7200"][data-aos],
[data-aos][data-aos][data-aos-delay="7200"] {
  transition-delay: 7200ms;
}
body[data-aos-delay="7250"][data-aos],
[data-aos][data-aos][data-aos-delay="7250"] {
  transition-delay: 7250ms;
}
body[data-aos-delay="7300"][data-aos],
[data-aos][data-aos][data-aos-delay="7300"] {
  transition-delay: 7300ms;
}
body[data-aos-delay="7350"][data-aos],
[data-aos][data-aos][data-aos-delay="7350"] {
  transition-delay: 7350ms;
}
body[data-aos-delay="7400"][data-aos],
[data-aos][data-aos][data-aos-delay="7400"] {
  transition-delay: 7400ms;
}
body[data-aos-delay="7450"][data-aos],
[data-aos][data-aos][data-aos-delay="7450"] {
  transition-delay: 7450ms;
}
body[data-aos-delay="7500"][data-aos],
[data-aos][data-aos][data-aos-delay="7500"] {
  transition-delay: 7500ms;
}
body[data-aos-delay="7550"][data-aos],
[data-aos][data-aos][data-aos-delay="7550"] {
  transition-delay: 7550ms;
}
body[data-aos-delay="7600"][data-aos],
[data-aos][data-aos][data-aos-delay="7600"] {
  transition-delay: 7600ms;
}
body[data-aos-delay="7650"][data-aos],
[data-aos][data-aos][data-aos-delay="7650"] {
  transition-delay: 7650ms;
}
body[data-aos-delay="7700"][data-aos],
[data-aos][data-aos][data-aos-delay="7700"] {
  transition-delay: 7700ms;
}
body[data-aos-delay="7750"][data-aos],
[data-aos][data-aos][data-aos-delay="7750"] {
  transition-delay: 7750ms;
}
body[data-aos-delay="7800"][data-aos],
[data-aos][data-aos][data-aos-delay="7800"] {
  transition-delay: 7800ms;
}
body[data-aos-delay="7850"][data-aos],
[data-aos][data-aos][data-aos-delay="7850"] {
  transition-delay: 7850ms;
}
body[data-aos-delay="7900"][data-aos],
[data-aos][data-aos][data-aos-delay="7900"] {
  transition-delay: 7900ms;
}
body[data-aos-delay="7950"][data-aos],
[data-aos][data-aos][data-aos-delay="7950"] {
  transition-delay: 7950ms;
}
body[data-aos-delay="8000"][data-aos],
[data-aos][data-aos][data-aos-delay="8000"] {
  transition-delay: 8000ms;
}
body[data-aos-delay="8050"][data-aos],
[data-aos][data-aos][data-aos-delay="8050"] {
  transition-delay: 8050ms;
}
body[data-aos-delay="8100"][data-aos],
[data-aos][data-aos][data-aos-delay="8100"] {
  transition-delay: 8100ms;
}
body[data-aos-delay="8150"][data-aos],
[data-aos][data-aos][data-aos-delay="8150"] {
  transition-delay: 8150ms;
}
body[data-aos-delay="8200"][data-aos],
[data-aos][data-aos][data-aos-delay="8200"] {
  transition-delay: 8200ms;
}
body[data-aos-delay="8250"][data-aos],
[data-aos][data-aos][data-aos-delay="8250"] {
  transition-delay: 8250ms;
}
body[data-aos-delay="8300"][data-aos],
[data-aos][data-aos][data-aos-delay="8300"] {
  transition-delay: 8300ms;
}
body[data-aos-delay="8350"][data-aos],
[data-aos][data-aos][data-aos-delay="8350"] {
  transition-delay: 8350ms;
}
body[data-aos-delay="8400"][data-aos],
[data-aos][data-aos][data-aos-delay="8400"] {
  transition-delay: 8400ms;
}
body[data-aos-delay="8450"][data-aos],
[data-aos][data-aos][data-aos-delay="8450"] {
  transition-delay: 8450ms;
}
body[data-aos-delay="8500"][data-aos],
[data-aos][data-aos][data-aos-delay="8500"] {
  transition-delay: 8500ms;
}
body[data-aos-delay="8550"][data-aos],
[data-aos][data-aos][data-aos-delay="8550"] {
  transition-delay: 8550ms;
}
body[data-aos-delay="8600"][data-aos],
[data-aos][data-aos][data-aos-delay="8600"] {
  transition-delay: 8600ms;
}
body[data-aos-delay="8650"][data-aos],
[data-aos][data-aos][data-aos-delay="8650"] {
  transition-delay: 8650ms;
}
body[data-aos-delay="8700"][data-aos],
[data-aos][data-aos][data-aos-delay="8700"] {
  transition-delay: 8700ms;
}
body[data-aos-delay="8750"][data-aos],
[data-aos][data-aos][data-aos-delay="8750"] {
  transition-delay: 8750ms;
}
body[data-aos-delay="8800"][data-aos],
[data-aos][data-aos][data-aos-delay="8800"] {
  transition-delay: 8800ms;
}
body[data-aos-delay="8850"][data-aos],
[data-aos][data-aos][data-aos-delay="8850"] {
  transition-delay: 8850ms;
}
body[data-aos-delay="8900"][data-aos],
[data-aos][data-aos][data-aos-delay="8900"] {
  transition-delay: 8900ms;
}
body[data-aos-delay="8950"][data-aos],
[data-aos][data-aos][data-aos-delay="8950"] {
  transition-delay: 8950ms;
}
body[data-aos-delay="9000"][data-aos],
[data-aos][data-aos][data-aos-delay="9000"] {
  transition-delay: 9000ms;
}
body[data-aos-delay="9050"][data-aos],
[data-aos][data-aos][data-aos-delay="9050"] {
  transition-delay: 9050ms;
}
body[data-aos-delay="9100"][data-aos],
[data-aos][data-aos][data-aos-delay="9100"] {
  transition-delay: 9100ms;
}
body[data-aos-delay="9150"][data-aos],
[data-aos][data-aos][data-aos-delay="9150"] {
  transition-delay: 9150ms;
}
body[data-aos-delay="9200"][data-aos],
[data-aos][data-aos][data-aos-delay="9200"] {
  transition-delay: 9200ms;
}
body[data-aos-delay="9250"][data-aos],
[data-aos][data-aos][data-aos-delay="9250"] {
  transition-delay: 9250ms;
}
body[data-aos-delay="9300"][data-aos],
[data-aos][data-aos][data-aos-delay="9300"] {
  transition-delay: 9300ms;
}
body[data-aos-delay="9350"][data-aos],
[data-aos][data-aos][data-aos-delay="9350"] {
  transition-delay: 9350ms;
}
body[data-aos-delay="9400"][data-aos],
[data-aos][data-aos][data-aos-delay="9400"] {
  transition-delay: 9400ms;
}
body[data-aos-delay="9450"][data-aos],
[data-aos][data-aos][data-aos-delay="9450"] {
  transition-delay: 9450ms;
}
body[data-aos-delay="9500"][data-aos],
[data-aos][data-aos][data-aos-delay="9500"] {
  transition-delay: 9500ms;
}
body[data-aos-delay="9550"][data-aos],
[data-aos][data-aos][data-aos-delay="9550"] {
  transition-delay: 9550ms;
}
body[data-aos-delay="9600"][data-aos],
[data-aos][data-aos][data-aos-delay="9600"] {
  transition-delay: 9600ms;
}
body[data-aos-delay="9650"][data-aos],
[data-aos][data-aos][data-aos-delay="9650"] {
  transition-delay: 9650ms;
}
body[data-aos-delay="9700"][data-aos],
[data-aos][data-aos][data-aos-delay="9700"] {
  transition-delay: 9700ms;
}
body[data-aos-delay="9750"][data-aos],
[data-aos][data-aos][data-aos-delay="9750"] {
  transition-delay: 9750ms;
}
body[data-aos-delay="9800"][data-aos],
[data-aos][data-aos][data-aos-delay="9800"] {
  transition-delay: 9800ms;
}
body[data-aos-delay="9850"][data-aos],
[data-aos][data-aos][data-aos-delay="9850"] {
  transition-delay: 9850ms;
}
body[data-aos-delay="9900"][data-aos],
[data-aos][data-aos][data-aos-delay="9900"] {
  transition-delay: 9900ms;
}
body[data-aos-delay="9950"][data-aos],
[data-aos][data-aos][data-aos-delay="9950"] {
  transition-delay: 9950ms;
}
body[data-aos-delay="10000"][data-aos],
[data-aos][data-aos][data-aos-delay="10000"] {
  transition-delay: 10000ms;
}
body[data-aos-delay="10050"][data-aos],
[data-aos][data-aos][data-aos-delay="10050"] {
  transition-delay: 10050ms;
}
body[data-aos-delay="10100"][data-aos],
[data-aos][data-aos][data-aos-delay="10100"] {
  transition-delay: 10100ms;
}
body[data-aos-delay="10150"][data-aos],
[data-aos][data-aos][data-aos-delay="10150"] {
  transition-delay: 10150ms;
}
body[data-aos-delay="10200"][data-aos],
[data-aos][data-aos][data-aos-delay="10200"] {
  transition-delay: 10200ms;
}
body[data-aos-delay="10250"][data-aos],
[data-aos][data-aos][data-aos-delay="10250"] {
  transition-delay: 10250ms;
}
body[data-aos-delay="10300"][data-aos],
[data-aos][data-aos][data-aos-delay="10300"] {
  transition-delay: 10300ms;
}
body[data-aos-delay="10350"][data-aos],
[data-aos][data-aos][data-aos-delay="10350"] {
  transition-delay: 10350ms;
}
body[data-aos-delay="10400"][data-aos],
[data-aos][data-aos][data-aos-delay="10400"] {
  transition-delay: 10400ms;
}
body[data-aos-delay="10450"][data-aos],
[data-aos][data-aos][data-aos-delay="10450"] {
  transition-delay: 10450ms;
}
body[data-aos-delay="10500"][data-aos],
[data-aos][data-aos][data-aos-delay="10500"] {
  transition-delay: 10500ms;
}
body[data-aos-delay="10550"][data-aos],
[data-aos][data-aos][data-aos-delay="10550"] {
  transition-delay: 10550ms;
}
body[data-aos-delay="10600"][data-aos],
[data-aos][data-aos][data-aos-delay="10600"] {
  transition-delay: 10600ms;
}
body[data-aos-delay="10650"][data-aos],
[data-aos][data-aos][data-aos-delay="10650"] {
  transition-delay: 10650ms;
}
body[data-aos-delay="10700"][data-aos],
[data-aos][data-aos][data-aos-delay="10700"] {
  transition-delay: 10700ms;
}
body[data-aos-delay="10750"][data-aos],
[data-aos][data-aos][data-aos-delay="10750"] {
  transition-delay: 10750ms;
}
body[data-aos-delay="10800"][data-aos],
[data-aos][data-aos][data-aos-delay="10800"] {
  transition-delay: 10800ms;
}
body[data-aos-delay="10850"][data-aos],
[data-aos][data-aos][data-aos-delay="10850"] {
  transition-delay: 10850ms;
}
body[data-aos-delay="10900"][data-aos],
[data-aos][data-aos][data-aos-delay="10900"] {
  transition-delay: 10900ms;
}
body[data-aos-delay="10950"][data-aos],
[data-aos][data-aos][data-aos-delay="10950"] {
  transition-delay: 10950ms;
}
body[data-aos-delay="11000"][data-aos],
[data-aos][data-aos][data-aos-delay="11000"] {
  transition-delay: 11000ms;
}
body[data-aos-delay="11050"][data-aos],
[data-aos][data-aos][data-aos-delay="11050"] {
  transition-delay: 11050ms;
}
body[data-aos-delay="11100"][data-aos],
[data-aos][data-aos][data-aos-delay="11100"] {
  transition-delay: 11100ms;
}
body[data-aos-delay="11150"][data-aos],
[data-aos][data-aos][data-aos-delay="11150"] {
  transition-delay: 11150ms;
}
body[data-aos-delay="11200"][data-aos],
[data-aos][data-aos][data-aos-delay="11200"] {
  transition-delay: 11200ms;
}
body[data-aos-delay="11250"][data-aos],
[data-aos][data-aos][data-aos-delay="11250"] {
  transition-delay: 11250ms;
}
body[data-aos-delay="11300"][data-aos],
[data-aos][data-aos][data-aos-delay="11300"] {
  transition-delay: 11300ms;
}
body[data-aos-delay="11350"][data-aos],
[data-aos][data-aos][data-aos-delay="11350"] {
  transition-delay: 11350ms;
}
body[data-aos-delay="11400"][data-aos],
[data-aos][data-aos][data-aos-delay="11400"] {
  transition-delay: 11400ms;
}
body[data-aos-delay="11450"][data-aos],
[data-aos][data-aos][data-aos-delay="11450"] {
  transition-delay: 11450ms;
}
body[data-aos-delay="11500"][data-aos],
[data-aos][data-aos][data-aos-delay="11500"] {
  transition-delay: 11500ms;
}
body[data-aos-delay="11550"][data-aos],
[data-aos][data-aos][data-aos-delay="11550"] {
  transition-delay: 11550ms;
}
body[data-aos-delay="11600"][data-aos],
[data-aos][data-aos][data-aos-delay="11600"] {
  transition-delay: 11600ms;
}
body[data-aos-delay="11650"][data-aos],
[data-aos][data-aos][data-aos-delay="11650"] {
  transition-delay: 11650ms;
}
body[data-aos-delay="11700"][data-aos],
[data-aos][data-aos][data-aos-delay="11700"] {
  transition-delay: 11700ms;
}
body[data-aos-delay="11750"][data-aos],
[data-aos][data-aos][data-aos-delay="11750"] {
  transition-delay: 11750ms;
}
body[data-aos-delay="11800"][data-aos],
[data-aos][data-aos][data-aos-delay="11800"] {
  transition-delay: 11800ms;
}
body[data-aos-delay="11850"][data-aos],
[data-aos][data-aos][data-aos-delay="11850"] {
  transition-delay: 11850ms;
}
body[data-aos-delay="11900"][data-aos],
[data-aos][data-aos][data-aos-delay="11900"] {
  transition-delay: 11900ms;
}
body[data-aos-delay="11950"][data-aos],
[data-aos][data-aos][data-aos-delay="11950"] {
  transition-delay: 11950ms;
}
body[data-aos-delay="12000"][data-aos],
[data-aos][data-aos][data-aos-delay="12000"] {
  transition-delay: 12000ms;
}
body[data-aos-delay="12050"][data-aos],
[data-aos][data-aos][data-aos-delay="12050"] {
  transition-delay: 12050ms;
}
body[data-aos-delay="12100"][data-aos],
[data-aos][data-aos][data-aos-delay="12100"] {
  transition-delay: 12100ms;
}
body[data-aos-delay="12150"][data-aos],
[data-aos][data-aos][data-aos-delay="12150"] {
  transition-delay: 12150ms;
}
body[data-aos-delay="12200"][data-aos],
[data-aos][data-aos][data-aos-delay="12200"] {
  transition-delay: 12200ms;
}
body[data-aos-delay="12250"][data-aos],
[data-aos][data-aos][data-aos-delay="12250"] {
  transition-delay: 12250ms;
}
body[data-aos-delay="12300"][data-aos],
[data-aos][data-aos][data-aos-delay="12300"] {
  transition-delay: 12300ms;
}
body[data-aos-delay="12350"][data-aos],
[data-aos][data-aos][data-aos-delay="12350"] {
  transition-delay: 12350ms;
}
body[data-aos-delay="12400"][data-aos],
[data-aos][data-aos][data-aos-delay="12400"] {
  transition-delay: 12400ms;
}
body[data-aos-delay="12450"][data-aos],
[data-aos][data-aos][data-aos-delay="12450"] {
  transition-delay: 12450ms;
}
body[data-aos-delay="12500"][data-aos],
[data-aos][data-aos][data-aos-delay="12500"] {
  transition-delay: 12500ms;
}
body[data-aos-delay="12550"][data-aos],
[data-aos][data-aos][data-aos-delay="12550"] {
  transition-delay: 12550ms;
}
body[data-aos-delay="12600"][data-aos],
[data-aos][data-aos][data-aos-delay="12600"] {
  transition-delay: 12600ms;
}
body[data-aos-delay="12650"][data-aos],
[data-aos][data-aos][data-aos-delay="12650"] {
  transition-delay: 12650ms;
}
body[data-aos-delay="12700"][data-aos],
[data-aos][data-aos][data-aos-delay="12700"] {
  transition-delay: 12700ms;
}
body[data-aos-delay="12750"][data-aos],
[data-aos][data-aos][data-aos-delay="12750"] {
  transition-delay: 12750ms;
}
body[data-aos-delay="12800"][data-aos],
[data-aos][data-aos][data-aos-delay="12800"] {
  transition-delay: 12800ms;
}
body[data-aos-delay="12850"][data-aos],
[data-aos][data-aos][data-aos-delay="12850"] {
  transition-delay: 12850ms;
}
body[data-aos-delay="12900"][data-aos],
[data-aos][data-aos][data-aos-delay="12900"] {
  transition-delay: 12900ms;
}
body[data-aos-delay="12950"][data-aos],
[data-aos][data-aos][data-aos-delay="12950"] {
  transition-delay: 12950ms;
}
body[data-aos-delay="13000"][data-aos],
[data-aos][data-aos][data-aos-delay="13000"] {
  transition-delay: 13000ms;
}
body[data-aos-delay="13050"][data-aos],
[data-aos][data-aos][data-aos-delay="13050"] {
  transition-delay: 13050ms;
}
body[data-aos-delay="13100"][data-aos],
[data-aos][data-aos][data-aos-delay="13100"] {
  transition-delay: 13100ms;
}
body[data-aos-delay="13150"][data-aos],
[data-aos][data-aos][data-aos-delay="13150"] {
  transition-delay: 13150ms;
}
body[data-aos-delay="13200"][data-aos],
[data-aos][data-aos][data-aos-delay="13200"] {
  transition-delay: 13200ms;
}
body[data-aos-delay="13250"][data-aos],
[data-aos][data-aos][data-aos-delay="13250"] {
  transition-delay: 13250ms;
}
body[data-aos-delay="13300"][data-aos],
[data-aos][data-aos][data-aos-delay="13300"] {
  transition-delay: 13300ms;
}
body[data-aos-delay="13350"][data-aos],
[data-aos][data-aos][data-aos-delay="13350"] {
  transition-delay: 13350ms;
}
body[data-aos-delay="13400"][data-aos],
[data-aos][data-aos][data-aos-delay="13400"] {
  transition-delay: 13400ms;
}
body[data-aos-delay="13450"][data-aos],
[data-aos][data-aos][data-aos-delay="13450"] {
  transition-delay: 13450ms;
}
body[data-aos-delay="13500"][data-aos],
[data-aos][data-aos][data-aos-delay="13500"] {
  transition-delay: 13500ms;
}
body[data-aos-delay="13550"][data-aos],
[data-aos][data-aos][data-aos-delay="13550"] {
  transition-delay: 13550ms;
}
body[data-aos-delay="13600"][data-aos],
[data-aos][data-aos][data-aos-delay="13600"] {
  transition-delay: 13600ms;
}
body[data-aos-delay="13650"][data-aos],
[data-aos][data-aos][data-aos-delay="13650"] {
  transition-delay: 13650ms;
}
body[data-aos-delay="13700"][data-aos],
[data-aos][data-aos][data-aos-delay="13700"] {
  transition-delay: 13700ms;
}
body[data-aos-delay="13750"][data-aos],
[data-aos][data-aos][data-aos-delay="13750"] {
  transition-delay: 13750ms;
}
body[data-aos-delay="13800"][data-aos],
[data-aos][data-aos][data-aos-delay="13800"] {
  transition-delay: 13800ms;
}
body[data-aos-delay="13850"][data-aos],
[data-aos][data-aos][data-aos-delay="13850"] {
  transition-delay: 13850ms;
}
body[data-aos-delay="13900"][data-aos],
[data-aos][data-aos][data-aos-delay="13900"] {
  transition-delay: 13900ms;
}
body[data-aos-delay="13950"][data-aos],
[data-aos][data-aos][data-aos-delay="13950"] {
  transition-delay: 13950ms;
}
body[data-aos-delay="14000"][data-aos],
[data-aos][data-aos][data-aos-delay="14000"] {
  transition-delay: 14000ms;
}
body[data-aos-delay="14050"][data-aos],
[data-aos][data-aos][data-aos-delay="14050"] {
  transition-delay: 14050ms;
}
body[data-aos-delay="14100"][data-aos],
[data-aos][data-aos][data-aos-delay="14100"] {
  transition-delay: 14100ms;
}
body[data-aos-delay="14150"][data-aos],
[data-aos][data-aos][data-aos-delay="14150"] {
  transition-delay: 14150ms;
}
body[data-aos-delay="14200"][data-aos],
[data-aos][data-aos][data-aos-delay="14200"] {
  transition-delay: 14200ms;
}
body[data-aos-delay="14250"][data-aos],
[data-aos][data-aos][data-aos-delay="14250"] {
  transition-delay: 14250ms;
}
body[data-aos-delay="14300"][data-aos],
[data-aos][data-aos][data-aos-delay="14300"] {
  transition-delay: 14300ms;
}
body[data-aos-delay="14350"][data-aos],
[data-aos][data-aos][data-aos-delay="14350"] {
  transition-delay: 14350ms;
}
body[data-aos-delay="14400"][data-aos],
[data-aos][data-aos][data-aos-delay="14400"] {
  transition-delay: 14400ms;
}
body[data-aos-delay="14450"][data-aos],
[data-aos][data-aos][data-aos-delay="14450"] {
  transition-delay: 14450ms;
}
body[data-aos-delay="14500"][data-aos],
[data-aos][data-aos][data-aos-delay="14500"] {
  transition-delay: 14500ms;
}
body[data-aos-delay="14550"][data-aos],
[data-aos][data-aos][data-aos-delay="14550"] {
  transition-delay: 14550ms;
}
body[data-aos-delay="14600"][data-aos],
[data-aos][data-aos][data-aos-delay="14600"] {
  transition-delay: 14600ms;
}
body[data-aos-delay="14650"][data-aos],
[data-aos][data-aos][data-aos-delay="14650"] {
  transition-delay: 14650ms;
}
body[data-aos-delay="14700"][data-aos],
[data-aos][data-aos][data-aos-delay="14700"] {
  transition-delay: 14700ms;
}
body[data-aos-delay="14750"][data-aos],
[data-aos][data-aos][data-aos-delay="14750"] {
  transition-delay: 14750ms;
}
body[data-aos-delay="14800"][data-aos],
[data-aos][data-aos][data-aos-delay="14800"] {
  transition-delay: 14800ms;
}
body[data-aos-delay="14850"][data-aos],
[data-aos][data-aos][data-aos-delay="14850"] {
  transition-delay: 14850ms;
}
body[data-aos-delay="14900"][data-aos],
[data-aos][data-aos][data-aos-delay="14900"] {
  transition-delay: 14900ms;
}
body[data-aos-delay="14950"][data-aos],
[data-aos][data-aos][data-aos-delay="14950"] {
  transition-delay: 14950ms;
}
body[data-aos-delay="15000"][data-aos],
[data-aos][data-aos][data-aos-delay="15000"] {
  transition-delay: 15000ms;
}
body[data-aos-delay="15050"][data-aos],
[data-aos][data-aos][data-aos-delay="15050"] {
  transition-delay: 15050ms;
}
body[data-aos-delay="15100"][data-aos],
[data-aos][data-aos][data-aos-delay="15100"] {
  transition-delay: 15100ms;
}
body[data-aos-delay="15150"][data-aos],
[data-aos][data-aos][data-aos-delay="15150"] {
  transition-delay: 15150ms;
}
body[data-aos-delay="15200"][data-aos],
[data-aos][data-aos][data-aos-delay="15200"] {
  transition-delay: 15200ms;
}
body[data-aos-delay="15250"][data-aos],
[data-aos][data-aos][data-aos-delay="15250"] {
  transition-delay: 15250ms;
}
body[data-aos-delay="15300"][data-aos],
[data-aos][data-aos][data-aos-delay="15300"] {
  transition-delay: 15300ms;
}
body[data-aos-delay="15350"][data-aos],
[data-aos][data-aos][data-aos-delay="15350"] {
  transition-delay: 15350ms;
}
body[data-aos-delay="15400"][data-aos],
[data-aos][data-aos][data-aos-delay="15400"] {
  transition-delay: 15400ms;
}
body[data-aos-delay="15450"][data-aos],
[data-aos][data-aos][data-aos-delay="15450"] {
  transition-delay: 15450ms;
}
body[data-aos-delay="15500"][data-aos],
[data-aos][data-aos][data-aos-delay="15500"] {
  transition-delay: 15500ms;
}
body[data-aos-delay="15550"][data-aos],
[data-aos][data-aos][data-aos-delay="15550"] {
  transition-delay: 15550ms;
}
body[data-aos-delay="15600"][data-aos],
[data-aos][data-aos][data-aos-delay="15600"] {
  transition-delay: 15600ms;
}
body[data-aos-delay="15650"][data-aos],
[data-aos][data-aos][data-aos-delay="15650"] {
  transition-delay: 15650ms;
}
body[data-aos-delay="15700"][data-aos],
[data-aos][data-aos][data-aos-delay="15700"] {
  transition-delay: 15700ms;
}
body[data-aos-delay="15750"][data-aos],
[data-aos][data-aos][data-aos-delay="15750"] {
  transition-delay: 15750ms;
}
body[data-aos-delay="15800"][data-aos],
[data-aos][data-aos][data-aos-delay="15800"] {
  transition-delay: 15800ms;
}
body[data-aos-delay="15850"][data-aos],
[data-aos][data-aos][data-aos-delay="15850"] {
  transition-delay: 15850ms;
}
body[data-aos-delay="15900"][data-aos],
[data-aos][data-aos][data-aos-delay="15900"] {
  transition-delay: 15900ms;
}
body[data-aos-delay="15950"][data-aos],
[data-aos][data-aos][data-aos-delay="15950"] {
  transition-delay: 15950ms;
}
body[data-aos-delay="16000"][data-aos],
[data-aos][data-aos][data-aos-delay="16000"] {
  transition-delay: 16000ms;
}
body[data-aos-delay="16050"][data-aos],
[data-aos][data-aos][data-aos-delay="16050"] {
  transition-delay: 16050ms;
}
body[data-aos-delay="16100"][data-aos],
[data-aos][data-aos][data-aos-delay="16100"] {
  transition-delay: 16100ms;
}
body[data-aos-delay="16150"][data-aos],
[data-aos][data-aos][data-aos-delay="16150"] {
  transition-delay: 16150ms;
}
body[data-aos-delay="16200"][data-aos],
[data-aos][data-aos][data-aos-delay="16200"] {
  transition-delay: 16200ms;
}
body[data-aos-delay="16250"][data-aos],
[data-aos][data-aos][data-aos-delay="16250"] {
  transition-delay: 16250ms;
}
body[data-aos-delay="16300"][data-aos],
[data-aos][data-aos][data-aos-delay="16300"] {
  transition-delay: 16300ms;
}
body[data-aos-delay="16350"][data-aos],
[data-aos][data-aos][data-aos-delay="16350"] {
  transition-delay: 16350ms;
}
body[data-aos-delay="16400"][data-aos],
[data-aos][data-aos][data-aos-delay="16400"] {
  transition-delay: 16400ms;
}
body[data-aos-delay="16450"][data-aos],
[data-aos][data-aos][data-aos-delay="16450"] {
  transition-delay: 16450ms;
}
body[data-aos-delay="16500"][data-aos],
[data-aos][data-aos][data-aos-delay="16500"] {
  transition-delay: 16500ms;
}
body[data-aos-delay="16550"][data-aos],
[data-aos][data-aos][data-aos-delay="16550"] {
  transition-delay: 16550ms;
}
body[data-aos-delay="16600"][data-aos],
[data-aos][data-aos][data-aos-delay="16600"] {
  transition-delay: 16600ms;
}
body[data-aos-delay="16650"][data-aos],
[data-aos][data-aos][data-aos-delay="16650"] {
  transition-delay: 16650ms;
}
body[data-aos-delay="16700"][data-aos],
[data-aos][data-aos][data-aos-delay="16700"] {
  transition-delay: 16700ms;
}
body[data-aos-delay="16750"][data-aos],
[data-aos][data-aos][data-aos-delay="16750"] {
  transition-delay: 16750ms;
}
body[data-aos-delay="16800"][data-aos],
[data-aos][data-aos][data-aos-delay="16800"] {
  transition-delay: 16800ms;
}
body[data-aos-delay="16850"][data-aos],
[data-aos][data-aos][data-aos-delay="16850"] {
  transition-delay: 16850ms;
}
body[data-aos-delay="16900"][data-aos],
[data-aos][data-aos][data-aos-delay="16900"] {
  transition-delay: 16900ms;
}
body[data-aos-delay="16950"][data-aos],
[data-aos][data-aos][data-aos-delay="16950"] {
  transition-delay: 16950ms;
}
body[data-aos-delay="17000"][data-aos],
[data-aos][data-aos][data-aos-delay="17000"] {
  transition-delay: 17000ms;
}
body[data-aos-delay="17050"][data-aos],
[data-aos][data-aos][data-aos-delay="17050"] {
  transition-delay: 17050ms;
}
body[data-aos-delay="17100"][data-aos],
[data-aos][data-aos][data-aos-delay="17100"] {
  transition-delay: 17100ms;
}
body[data-aos-delay="17150"][data-aos],
[data-aos][data-aos][data-aos-delay="17150"] {
  transition-delay: 17150ms;
}
body[data-aos-delay="17200"][data-aos],
[data-aos][data-aos][data-aos-delay="17200"] {
  transition-delay: 17200ms;
}
body[data-aos-delay="17250"][data-aos],
[data-aos][data-aos][data-aos-delay="17250"] {
  transition-delay: 17250ms;
}
body[data-aos-delay="17300"][data-aos],
[data-aos][data-aos][data-aos-delay="17300"] {
  transition-delay: 17300ms;
}
body[data-aos-delay="17350"][data-aos],
[data-aos][data-aos][data-aos-delay="17350"] {
  transition-delay: 17350ms;
}
body[data-aos-delay="17400"][data-aos],
[data-aos][data-aos][data-aos-delay="17400"] {
  transition-delay: 17400ms;
}
body[data-aos-delay="17450"][data-aos],
[data-aos][data-aos][data-aos-delay="17450"] {
  transition-delay: 17450ms;
}
body[data-aos-delay="17500"][data-aos],
[data-aos][data-aos][data-aos-delay="17500"] {
  transition-delay: 17500ms;
}
body[data-aos-delay="17550"][data-aos],
[data-aos][data-aos][data-aos-delay="17550"] {
  transition-delay: 17550ms;
}
body[data-aos-delay="17600"][data-aos],
[data-aos][data-aos][data-aos-delay="17600"] {
  transition-delay: 17600ms;
}
body[data-aos-delay="17650"][data-aos],
[data-aos][data-aos][data-aos-delay="17650"] {
  transition-delay: 17650ms;
}
body[data-aos-delay="17700"][data-aos],
[data-aos][data-aos][data-aos-delay="17700"] {
  transition-delay: 17700ms;
}
body[data-aos-delay="17750"][data-aos],
[data-aos][data-aos][data-aos-delay="17750"] {
  transition-delay: 17750ms;
}
body[data-aos-delay="17800"][data-aos],
[data-aos][data-aos][data-aos-delay="17800"] {
  transition-delay: 17800ms;
}
body[data-aos-delay="17850"][data-aos],
[data-aos][data-aos][data-aos-delay="17850"] {
  transition-delay: 17850ms;
}
body[data-aos-delay="17900"][data-aos],
[data-aos][data-aos][data-aos-delay="17900"] {
  transition-delay: 17900ms;
}
body[data-aos-delay="17950"][data-aos],
[data-aos][data-aos][data-aos-delay="17950"] {
  transition-delay: 17950ms;
}
body[data-aos-delay="18000"][data-aos],
[data-aos][data-aos][data-aos-delay="18000"] {
  transition-delay: 18000ms;
}
body[data-aos-delay="18050"][data-aos],
[data-aos][data-aos][data-aos-delay="18050"] {
  transition-delay: 18050ms;
}
body[data-aos-delay="18100"][data-aos],
[data-aos][data-aos][data-aos-delay="18100"] {
  transition-delay: 18100ms;
}
body[data-aos-delay="18150"][data-aos],
[data-aos][data-aos][data-aos-delay="18150"] {
  transition-delay: 18150ms;
}
body[data-aos-delay="18200"][data-aos],
[data-aos][data-aos][data-aos-delay="18200"] {
  transition-delay: 18200ms;
}
body[data-aos-delay="18250"][data-aos],
[data-aos][data-aos][data-aos-delay="18250"] {
  transition-delay: 18250ms;
}
body[data-aos-delay="18300"][data-aos],
[data-aos][data-aos][data-aos-delay="18300"] {
  transition-delay: 18300ms;
}
body[data-aos-delay="18350"][data-aos],
[data-aos][data-aos][data-aos-delay="18350"] {
  transition-delay: 18350ms;
}
body[data-aos-delay="18400"][data-aos],
[data-aos][data-aos][data-aos-delay="18400"] {
  transition-delay: 18400ms;
}
body[data-aos-delay="18450"][data-aos],
[data-aos][data-aos][data-aos-delay="18450"] {
  transition-delay: 18450ms;
}
body[data-aos-delay="18500"][data-aos],
[data-aos][data-aos][data-aos-delay="18500"] {
  transition-delay: 18500ms;
}
body[data-aos-delay="18550"][data-aos],
[data-aos][data-aos][data-aos-delay="18550"] {
  transition-delay: 18550ms;
}
body[data-aos-delay="18600"][data-aos],
[data-aos][data-aos][data-aos-delay="18600"] {
  transition-delay: 18600ms;
}
body[data-aos-delay="18650"][data-aos],
[data-aos][data-aos][data-aos-delay="18650"] {
  transition-delay: 18650ms;
}
body[data-aos-delay="18700"][data-aos],
[data-aos][data-aos][data-aos-delay="18700"] {
  transition-delay: 18700ms;
}
body[data-aos-delay="18750"][data-aos],
[data-aos][data-aos][data-aos-delay="18750"] {
  transition-delay: 18750ms;
}
body[data-aos-delay="18800"][data-aos],
[data-aos][data-aos][data-aos-delay="18800"] {
  transition-delay: 18800ms;
}
body[data-aos-delay="18850"][data-aos],
[data-aos][data-aos][data-aos-delay="18850"] {
  transition-delay: 18850ms;
}
body[data-aos-delay="18900"][data-aos],
[data-aos][data-aos][data-aos-delay="18900"] {
  transition-delay: 18900ms;
}
body[data-aos-delay="18950"][data-aos],
[data-aos][data-aos][data-aos-delay="18950"] {
  transition-delay: 18950ms;
}
body[data-aos-delay="19000"][data-aos],
[data-aos][data-aos][data-aos-delay="19000"] {
  transition-delay: 19000ms;
}
body[data-aos-delay="19050"][data-aos],
[data-aos][data-aos][data-aos-delay="19050"] {
  transition-delay: 19050ms;
}
body[data-aos-delay="19100"][data-aos],
[data-aos][data-aos][data-aos-delay="19100"] {
  transition-delay: 19100ms;
}
body[data-aos-delay="19150"][data-aos],
[data-aos][data-aos][data-aos-delay="19150"] {
  transition-delay: 19150ms;
}
body[data-aos-delay="19200"][data-aos],
[data-aos][data-aos][data-aos-delay="19200"] {
  transition-delay: 19200ms;
}
body[data-aos-delay="19250"][data-aos],
[data-aos][data-aos][data-aos-delay="19250"] {
  transition-delay: 19250ms;
}
body[data-aos-delay="19300"][data-aos],
[data-aos][data-aos][data-aos-delay="19300"] {
  transition-delay: 19300ms;
}
body[data-aos-delay="19350"][data-aos],
[data-aos][data-aos][data-aos-delay="19350"] {
  transition-delay: 19350ms;
}
body[data-aos-delay="19400"][data-aos],
[data-aos][data-aos][data-aos-delay="19400"] {
  transition-delay: 19400ms;
}
body[data-aos-delay="19450"][data-aos],
[data-aos][data-aos][data-aos-delay="19450"] {
  transition-delay: 19450ms;
}
body[data-aos-delay="19500"][data-aos],
[data-aos][data-aos][data-aos-delay="19500"] {
  transition-delay: 19500ms;
}
body[data-aos-delay="19550"][data-aos],
[data-aos][data-aos][data-aos-delay="19550"] {
  transition-delay: 19550ms;
}
body[data-aos-delay="19600"][data-aos],
[data-aos][data-aos][data-aos-delay="19600"] {
  transition-delay: 19600ms;
}
body[data-aos-delay="19650"][data-aos],
[data-aos][data-aos][data-aos-delay="19650"] {
  transition-delay: 19650ms;
}
body[data-aos-delay="19700"][data-aos],
[data-aos][data-aos][data-aos-delay="19700"] {
  transition-delay: 19700ms;
}
body[data-aos-delay="19750"][data-aos],
[data-aos][data-aos][data-aos-delay="19750"] {
  transition-delay: 19750ms;
}
body[data-aos-delay="19800"][data-aos],
[data-aos][data-aos][data-aos-delay="19800"] {
  transition-delay: 19800ms;
}
body[data-aos-delay="19850"][data-aos],
[data-aos][data-aos][data-aos-delay="19850"] {
  transition-delay: 19850ms;
}
body[data-aos-delay="19900"][data-aos],
[data-aos][data-aos][data-aos-delay="19900"] {
  transition-delay: 19900ms;
}
body[data-aos-delay="19950"][data-aos],
[data-aos][data-aos][data-aos-delay="19950"] {
  transition-delay: 19950ms;
}
body[data-aos-delay="20000"][data-aos],
[data-aos][data-aos][data-aos-delay="20000"] {
  transition-delay: 20000ms;
}
body[data-aos-delay="20050"][data-aos],
[data-aos][data-aos][data-aos-delay="20050"] {
  transition-delay: 20050ms;
}
body[data-aos-delay="20100"][data-aos],
[data-aos][data-aos][data-aos-delay="20100"] {
  transition-delay: 20100ms;
}
body[data-aos-delay="20150"][data-aos],
[data-aos][data-aos][data-aos-delay="20150"] {
  transition-delay: 20150ms;
}
body[data-aos-delay="20200"][data-aos],
[data-aos][data-aos][data-aos-delay="20200"] {
  transition-delay: 20200ms;
}
body[data-aos-delay="20250"][data-aos],
[data-aos][data-aos][data-aos-delay="20250"] {
  transition-delay: 20250ms;
}
body[data-aos-delay="20300"][data-aos],
[data-aos][data-aos][data-aos-delay="20300"] {
  transition-delay: 20300ms;
}
body[data-aos-delay="20350"][data-aos],
[data-aos][data-aos][data-aos-delay="20350"] {
  transition-delay: 20350ms;
}
body[data-aos-delay="20400"][data-aos],
[data-aos][data-aos][data-aos-delay="20400"] {
  transition-delay: 20400ms;
}
body[data-aos-delay="20450"][data-aos],
[data-aos][data-aos][data-aos-delay="20450"] {
  transition-delay: 20450ms;
}
body[data-aos-delay="20500"][data-aos],
[data-aos][data-aos][data-aos-delay="20500"] {
  transition-delay: 20500ms;
}
body[data-aos-delay="20550"][data-aos],
[data-aos][data-aos][data-aos-delay="20550"] {
  transition-delay: 20550ms;
}
body[data-aos-delay="20600"][data-aos],
[data-aos][data-aos][data-aos-delay="20600"] {
  transition-delay: 20600ms;
}
body[data-aos-delay="20650"][data-aos],
[data-aos][data-aos][data-aos-delay="20650"] {
  transition-delay: 20650ms;
}
body[data-aos-delay="20700"][data-aos],
[data-aos][data-aos][data-aos-delay="20700"] {
  transition-delay: 20700ms;
}
body[data-aos-delay="20750"][data-aos],
[data-aos][data-aos][data-aos-delay="20750"] {
  transition-delay: 20750ms;
}
body[data-aos-delay="20800"][data-aos],
[data-aos][data-aos][data-aos-delay="20800"] {
  transition-delay: 20800ms;
}
body[data-aos-delay="20850"][data-aos],
[data-aos][data-aos][data-aos-delay="20850"] {
  transition-delay: 20850ms;
}
body[data-aos-delay="20900"][data-aos],
[data-aos][data-aos][data-aos-delay="20900"] {
  transition-delay: 20900ms;
}
body[data-aos-delay="20950"][data-aos],
[data-aos][data-aos][data-aos-delay="20950"] {
  transition-delay: 20950ms;
}
body[data-aos-delay="21000"][data-aos],
[data-aos][data-aos][data-aos-delay="21000"] {
  transition-delay: 21000ms;
}
body[data-aos-delay="21050"][data-aos],
[data-aos][data-aos][data-aos-delay="21050"] {
  transition-delay: 21050ms;
}
body[data-aos-delay="21100"][data-aos],
[data-aos][data-aos][data-aos-delay="21100"] {
  transition-delay: 21100ms;
}
body[data-aos-delay="21150"][data-aos],
[data-aos][data-aos][data-aos-delay="21150"] {
  transition-delay: 21150ms;
}
body[data-aos-delay="21200"][data-aos],
[data-aos][data-aos][data-aos-delay="21200"] {
  transition-delay: 21200ms;
}
body[data-aos-delay="21250"][data-aos],
[data-aos][data-aos][data-aos-delay="21250"] {
  transition-delay: 21250ms;
}
body[data-aos-delay="21300"][data-aos],
[data-aos][data-aos][data-aos-delay="21300"] {
  transition-delay: 21300ms;
}
body[data-aos-delay="21350"][data-aos],
[data-aos][data-aos][data-aos-delay="21350"] {
  transition-delay: 21350ms;
}
body[data-aos-delay="21400"][data-aos],
[data-aos][data-aos][data-aos-delay="21400"] {
  transition-delay: 21400ms;
}
body[data-aos-delay="21450"][data-aos],
[data-aos][data-aos][data-aos-delay="21450"] {
  transition-delay: 21450ms;
}
body[data-aos-delay="21500"][data-aos],
[data-aos][data-aos][data-aos-delay="21500"] {
  transition-delay: 21500ms;
}
body[data-aos-delay="21550"][data-aos],
[data-aos][data-aos][data-aos-delay="21550"] {
  transition-delay: 21550ms;
}
body[data-aos-delay="21600"][data-aos],
[data-aos][data-aos][data-aos-delay="21600"] {
  transition-delay: 21600ms;
}
body[data-aos-delay="21650"][data-aos],
[data-aos][data-aos][data-aos-delay="21650"] {
  transition-delay: 21650ms;
}
body[data-aos-delay="21700"][data-aos],
[data-aos][data-aos][data-aos-delay="21700"] {
  transition-delay: 21700ms;
}
body[data-aos-delay="21750"][data-aos],
[data-aos][data-aos][data-aos-delay="21750"] {
  transition-delay: 21750ms;
}
body[data-aos-delay="21800"][data-aos],
[data-aos][data-aos][data-aos-delay="21800"] {
  transition-delay: 21800ms;
}
body[data-aos-delay="21850"][data-aos],
[data-aos][data-aos][data-aos-delay="21850"] {
  transition-delay: 21850ms;
}
body[data-aos-delay="21900"][data-aos],
[data-aos][data-aos][data-aos-delay="21900"] {
  transition-delay: 21900ms;
}
body[data-aos-delay="21950"][data-aos],
[data-aos][data-aos][data-aos-delay="21950"] {
  transition-delay: 21950ms;
}
body[data-aos-delay="22000"][data-aos],
[data-aos][data-aos][data-aos-delay="22000"] {
  transition-delay: 22000ms;
}
body[data-aos-delay="22050"][data-aos],
[data-aos][data-aos][data-aos-delay="22050"] {
  transition-delay: 22050ms;
}
body[data-aos-delay="22100"][data-aos],
[data-aos][data-aos][data-aos-delay="22100"] {
  transition-delay: 22100ms;
}
body[data-aos-delay="22150"][data-aos],
[data-aos][data-aos][data-aos-delay="22150"] {
  transition-delay: 22150ms;
}
body[data-aos-delay="22200"][data-aos],
[data-aos][data-aos][data-aos-delay="22200"] {
  transition-delay: 22200ms;
}
body[data-aos-delay="22250"][data-aos],
[data-aos][data-aos][data-aos-delay="22250"] {
  transition-delay: 22250ms;
}
body[data-aos-delay="22300"][data-aos],
[data-aos][data-aos][data-aos-delay="22300"] {
  transition-delay: 22300ms;
}
body[data-aos-delay="22350"][data-aos],
[data-aos][data-aos][data-aos-delay="22350"] {
  transition-delay: 22350ms;
}
body[data-aos-delay="22400"][data-aos],
[data-aos][data-aos][data-aos-delay="22400"] {
  transition-delay: 22400ms;
}
body[data-aos-delay="22450"][data-aos],
[data-aos][data-aos][data-aos-delay="22450"] {
  transition-delay: 22450ms;
}
body[data-aos-delay="22500"][data-aos],
[data-aos][data-aos][data-aos-delay="22500"] {
  transition-delay: 22500ms;
}
body[data-aos-delay="22550"][data-aos],
[data-aos][data-aos][data-aos-delay="22550"] {
  transition-delay: 22550ms;
}
body[data-aos-delay="22600"][data-aos],
[data-aos][data-aos][data-aos-delay="22600"] {
  transition-delay: 22600ms;
}
body[data-aos-delay="22650"][data-aos],
[data-aos][data-aos][data-aos-delay="22650"] {
  transition-delay: 22650ms;
}
body[data-aos-delay="22700"][data-aos],
[data-aos][data-aos][data-aos-delay="22700"] {
  transition-delay: 22700ms;
}
body[data-aos-delay="22750"][data-aos],
[data-aos][data-aos][data-aos-delay="22750"] {
  transition-delay: 22750ms;
}
body[data-aos-delay="22800"][data-aos],
[data-aos][data-aos][data-aos-delay="22800"] {
  transition-delay: 22800ms;
}
body[data-aos-delay="22850"][data-aos],
[data-aos][data-aos][data-aos-delay="22850"] {
  transition-delay: 22850ms;
}
body[data-aos-delay="22900"][data-aos],
[data-aos][data-aos][data-aos-delay="22900"] {
  transition-delay: 22900ms;
}
body[data-aos-delay="22950"][data-aos],
[data-aos][data-aos][data-aos-delay="22950"] {
  transition-delay: 22950ms;
}
body[data-aos-delay="23000"][data-aos],
[data-aos][data-aos][data-aos-delay="23000"] {
  transition-delay: 23000ms;
}
body[data-aos-delay="23050"][data-aos],
[data-aos][data-aos][data-aos-delay="23050"] {
  transition-delay: 23050ms;
}
body[data-aos-delay="23100"][data-aos],
[data-aos][data-aos][data-aos-delay="23100"] {
  transition-delay: 23100ms;
}
body[data-aos-delay="23150"][data-aos],
[data-aos][data-aos][data-aos-delay="23150"] {
  transition-delay: 23150ms;
}
body[data-aos-delay="23200"][data-aos],
[data-aos][data-aos][data-aos-delay="23200"] {
  transition-delay: 23200ms;
}
body[data-aos-delay="23250"][data-aos],
[data-aos][data-aos][data-aos-delay="23250"] {
  transition-delay: 23250ms;
}
body[data-aos-delay="23300"][data-aos],
[data-aos][data-aos][data-aos-delay="23300"] {
  transition-delay: 23300ms;
}
body[data-aos-delay="23350"][data-aos],
[data-aos][data-aos][data-aos-delay="23350"] {
  transition-delay: 23350ms;
}
body[data-aos-delay="23400"][data-aos],
[data-aos][data-aos][data-aos-delay="23400"] {
  transition-delay: 23400ms;
}
body[data-aos-delay="23450"][data-aos],
[data-aos][data-aos][data-aos-delay="23450"] {
  transition-delay: 23450ms;
}
body[data-aos-delay="23500"][data-aos],
[data-aos][data-aos][data-aos-delay="23500"] {
  transition-delay: 23500ms;
}
body[data-aos-delay="23550"][data-aos],
[data-aos][data-aos][data-aos-delay="23550"] {
  transition-delay: 23550ms;
}
body[data-aos-delay="23600"][data-aos],
[data-aos][data-aos][data-aos-delay="23600"] {
  transition-delay: 23600ms;
}
body[data-aos-delay="23650"][data-aos],
[data-aos][data-aos][data-aos-delay="23650"] {
  transition-delay: 23650ms;
}
body[data-aos-delay="23700"][data-aos],
[data-aos][data-aos][data-aos-delay="23700"] {
  transition-delay: 23700ms;
}
body[data-aos-delay="23750"][data-aos],
[data-aos][data-aos][data-aos-delay="23750"] {
  transition-delay: 23750ms;
}
body[data-aos-delay="23800"][data-aos],
[data-aos][data-aos][data-aos-delay="23800"] {
  transition-delay: 23800ms;
}
body[data-aos-delay="23850"][data-aos],
[data-aos][data-aos][data-aos-delay="23850"] {
  transition-delay: 23850ms;
}
body[data-aos-delay="23900"][data-aos],
[data-aos][data-aos][data-aos-delay="23900"] {
  transition-delay: 23900ms;
}
body[data-aos-delay="23950"][data-aos],
[data-aos][data-aos][data-aos-delay="23950"] {
  transition-delay: 23950ms;
}
body[data-aos-delay="24000"][data-aos],
[data-aos][data-aos][data-aos-delay="24000"] {
  transition-delay: 24000ms;
}
body[data-aos-delay="24050"][data-aos],
[data-aos][data-aos][data-aos-delay="24050"] {
  transition-delay: 24050ms;
}
body[data-aos-delay="24100"][data-aos],
[data-aos][data-aos][data-aos-delay="24100"] {
  transition-delay: 24100ms;
}
body[data-aos-delay="24150"][data-aos],
[data-aos][data-aos][data-aos-delay="24150"] {
  transition-delay: 24150ms;
}
body[data-aos-delay="24200"][data-aos],
[data-aos][data-aos][data-aos-delay="24200"] {
  transition-delay: 24200ms;
}
body[data-aos-delay="24250"][data-aos],
[data-aos][data-aos][data-aos-delay="24250"] {
  transition-delay: 24250ms;
}
body[data-aos-delay="24300"][data-aos],
[data-aos][data-aos][data-aos-delay="24300"] {
  transition-delay: 24300ms;
}
body[data-aos-delay="24350"][data-aos],
[data-aos][data-aos][data-aos-delay="24350"] {
  transition-delay: 24350ms;
}
body[data-aos-delay="24400"][data-aos],
[data-aos][data-aos][data-aos-delay="24400"] {
  transition-delay: 24400ms;
}
body[data-aos-delay="24450"][data-aos],
[data-aos][data-aos][data-aos-delay="24450"] {
  transition-delay: 24450ms;
}
body[data-aos-delay="24500"][data-aos],
[data-aos][data-aos][data-aos-delay="24500"] {
  transition-delay: 24500ms;
}
body[data-aos-delay="24550"][data-aos],
[data-aos][data-aos][data-aos-delay="24550"] {
  transition-delay: 24550ms;
}
body[data-aos-delay="24600"][data-aos],
[data-aos][data-aos][data-aos-delay="24600"] {
  transition-delay: 24600ms;
}
body[data-aos-delay="24650"][data-aos],
[data-aos][data-aos][data-aos-delay="24650"] {
  transition-delay: 24650ms;
}
body[data-aos-delay="24700"][data-aos],
[data-aos][data-aos][data-aos-delay="24700"] {
  transition-delay: 24700ms;
}
body[data-aos-delay="24750"][data-aos],
[data-aos][data-aos][data-aos-delay="24750"] {
  transition-delay: 24750ms;
}
body[data-aos-delay="24800"][data-aos],
[data-aos][data-aos][data-aos-delay="24800"] {
  transition-delay: 24800ms;
}
body[data-aos-delay="24850"][data-aos],
[data-aos][data-aos][data-aos-delay="24850"] {
  transition-delay: 24850ms;
}
body[data-aos-delay="24900"][data-aos],
[data-aos][data-aos][data-aos-delay="24900"] {
  transition-delay: 24900ms;
}
body[data-aos-delay="24950"][data-aos],
[data-aos][data-aos][data-aos-delay="24950"] {
  transition-delay: 24950ms;
}
body[data-aos-delay="25000"][data-aos],
[data-aos][data-aos][data-aos-delay="25000"] {
  transition-delay: 25000ms;
}
body[data-aos-delay="25050"][data-aos],
[data-aos][data-aos][data-aos-delay="25050"] {
  transition-delay: 25050ms;
}
body[data-aos-delay="25100"][data-aos],
[data-aos][data-aos][data-aos-delay="25100"] {
  transition-delay: 25100ms;
}
body[data-aos-delay="25150"][data-aos],
[data-aos][data-aos][data-aos-delay="25150"] {
  transition-delay: 25150ms;
}
body[data-aos-delay="25200"][data-aos],
[data-aos][data-aos][data-aos-delay="25200"] {
  transition-delay: 25200ms;
}
body[data-aos-delay="25250"][data-aos],
[data-aos][data-aos][data-aos-delay="25250"] {
  transition-delay: 25250ms;
}
body[data-aos-delay="25300"][data-aos],
[data-aos][data-aos][data-aos-delay="25300"] {
  transition-delay: 25300ms;
}
body[data-aos-delay="25350"][data-aos],
[data-aos][data-aos][data-aos-delay="25350"] {
  transition-delay: 25350ms;
}
body[data-aos-delay="25400"][data-aos],
[data-aos][data-aos][data-aos-delay="25400"] {
  transition-delay: 25400ms;
}
body[data-aos-delay="25450"][data-aos],
[data-aos][data-aos][data-aos-delay="25450"] {
  transition-delay: 25450ms;
}
body[data-aos-delay="25500"][data-aos],
[data-aos][data-aos][data-aos-delay="25500"] {
  transition-delay: 25500ms;
}
body[data-aos-delay="25550"][data-aos],
[data-aos][data-aos][data-aos-delay="25550"] {
  transition-delay: 25550ms;
}
body[data-aos-delay="25600"][data-aos],
[data-aos][data-aos][data-aos-delay="25600"] {
  transition-delay: 25600ms;
}
body[data-aos-delay="25650"][data-aos],
[data-aos][data-aos][data-aos-delay="25650"] {
  transition-delay: 25650ms;
}
body[data-aos-delay="25700"][data-aos],
[data-aos][data-aos][data-aos-delay="25700"] {
  transition-delay: 25700ms;
}
body[data-aos-delay="25750"][data-aos],
[data-aos][data-aos][data-aos-delay="25750"] {
  transition-delay: 25750ms;
}
body[data-aos-delay="25800"][data-aos],
[data-aos][data-aos][data-aos-delay="25800"] {
  transition-delay: 25800ms;
}
body[data-aos-delay="25850"][data-aos],
[data-aos][data-aos][data-aos-delay="25850"] {
  transition-delay: 25850ms;
}
body[data-aos-delay="25900"][data-aos],
[data-aos][data-aos][data-aos-delay="25900"] {
  transition-delay: 25900ms;
}
body[data-aos-delay="25950"][data-aos],
[data-aos][data-aos][data-aos-delay="25950"] {
  transition-delay: 25950ms;
}
body[data-aos-delay="26000"][data-aos],
[data-aos][data-aos][data-aos-delay="26000"] {
  transition-delay: 26000ms;
}
body[data-aos-delay="26050"][data-aos],
[data-aos][data-aos][data-aos-delay="26050"] {
  transition-delay: 26050ms;
}
body[data-aos-delay="26100"][data-aos],
[data-aos][data-aos][data-aos-delay="26100"] {
  transition-delay: 26100ms;
}
body[data-aos-delay="26150"][data-aos],
[data-aos][data-aos][data-aos-delay="26150"] {
  transition-delay: 26150ms;
}
body[data-aos-delay="26200"][data-aos],
[data-aos][data-aos][data-aos-delay="26200"] {
  transition-delay: 26200ms;
}
body[data-aos-delay="26250"][data-aos],
[data-aos][data-aos][data-aos-delay="26250"] {
  transition-delay: 26250ms;
}
body[data-aos-delay="26300"][data-aos],
[data-aos][data-aos][data-aos-delay="26300"] {
  transition-delay: 26300ms;
}
body[data-aos-delay="26350"][data-aos],
[data-aos][data-aos][data-aos-delay="26350"] {
  transition-delay: 26350ms;
}
body[data-aos-delay="26400"][data-aos],
[data-aos][data-aos][data-aos-delay="26400"] {
  transition-delay: 26400ms;
}
body[data-aos-delay="26450"][data-aos],
[data-aos][data-aos][data-aos-delay="26450"] {
  transition-delay: 26450ms;
}
body[data-aos-delay="26500"][data-aos],
[data-aos][data-aos][data-aos-delay="26500"] {
  transition-delay: 26500ms;
}
body[data-aos-delay="26550"][data-aos],
[data-aos][data-aos][data-aos-delay="26550"] {
  transition-delay: 26550ms;
}
body[data-aos-delay="26600"][data-aos],
[data-aos][data-aos][data-aos-delay="26600"] {
  transition-delay: 26600ms;
}
body[data-aos-delay="26650"][data-aos],
[data-aos][data-aos][data-aos-delay="26650"] {
  transition-delay: 26650ms;
}
body[data-aos-delay="26700"][data-aos],
[data-aos][data-aos][data-aos-delay="26700"] {
  transition-delay: 26700ms;
}
body[data-aos-delay="26750"][data-aos],
[data-aos][data-aos][data-aos-delay="26750"] {
  transition-delay: 26750ms;
}
body[data-aos-delay="26800"][data-aos],
[data-aos][data-aos][data-aos-delay="26800"] {
  transition-delay: 26800ms;
}
body[data-aos-delay="26850"][data-aos],
[data-aos][data-aos][data-aos-delay="26850"] {
  transition-delay: 26850ms;
}
body[data-aos-delay="26900"][data-aos],
[data-aos][data-aos][data-aos-delay="26900"] {
  transition-delay: 26900ms;
}
body[data-aos-delay="26950"][data-aos],
[data-aos][data-aos][data-aos-delay="26950"] {
  transition-delay: 26950ms;
}
body[data-aos-delay="27000"][data-aos],
[data-aos][data-aos][data-aos-delay="27000"] {
  transition-delay: 27000ms;
}
body[data-aos-delay="27050"][data-aos],
[data-aos][data-aos][data-aos-delay="27050"] {
  transition-delay: 27050ms;
}
body[data-aos-delay="27100"][data-aos],
[data-aos][data-aos][data-aos-delay="27100"] {
  transition-delay: 27100ms;
}
body[data-aos-delay="27150"][data-aos],
[data-aos][data-aos][data-aos-delay="27150"] {
  transition-delay: 27150ms;
}
body[data-aos-delay="27200"][data-aos],
[data-aos][data-aos][data-aos-delay="27200"] {
  transition-delay: 27200ms;
}
body[data-aos-delay="27250"][data-aos],
[data-aos][data-aos][data-aos-delay="27250"] {
  transition-delay: 27250ms;
}
body[data-aos-delay="27300"][data-aos],
[data-aos][data-aos][data-aos-delay="27300"] {
  transition-delay: 27300ms;
}
body[data-aos-delay="27350"][data-aos],
[data-aos][data-aos][data-aos-delay="27350"] {
  transition-delay: 27350ms;
}
body[data-aos-delay="27400"][data-aos],
[data-aos][data-aos][data-aos-delay="27400"] {
  transition-delay: 27400ms;
}
body[data-aos-delay="27450"][data-aos],
[data-aos][data-aos][data-aos-delay="27450"] {
  transition-delay: 27450ms;
}
body[data-aos-delay="27500"][data-aos],
[data-aos][data-aos][data-aos-delay="27500"] {
  transition-delay: 27500ms;
}
body[data-aos-delay="27550"][data-aos],
[data-aos][data-aos][data-aos-delay="27550"] {
  transition-delay: 27550ms;
}
body[data-aos-delay="27600"][data-aos],
[data-aos][data-aos][data-aos-delay="27600"] {
  transition-delay: 27600ms;
}
body[data-aos-delay="27650"][data-aos],
[data-aos][data-aos][data-aos-delay="27650"] {
  transition-delay: 27650ms;
}
body[data-aos-delay="27700"][data-aos],
[data-aos][data-aos][data-aos-delay="27700"] {
  transition-delay: 27700ms;
}
body[data-aos-delay="27750"][data-aos],
[data-aos][data-aos][data-aos-delay="27750"] {
  transition-delay: 27750ms;
}
body[data-aos-delay="27800"][data-aos],
[data-aos][data-aos][data-aos-delay="27800"] {
  transition-delay: 27800ms;
}
body[data-aos-delay="27850"][data-aos],
[data-aos][data-aos][data-aos-delay="27850"] {
  transition-delay: 27850ms;
}
body[data-aos-delay="27900"][data-aos],
[data-aos][data-aos][data-aos-delay="27900"] {
  transition-delay: 27900ms;
}
body[data-aos-delay="27950"][data-aos],
[data-aos][data-aos][data-aos-delay="27950"] {
  transition-delay: 27950ms;
}
body[data-aos-delay="28000"][data-aos],
[data-aos][data-aos][data-aos-delay="28000"] {
  transition-delay: 28000ms;
}
body[data-aos-delay="28050"][data-aos],
[data-aos][data-aos][data-aos-delay="28050"] {
  transition-delay: 28050ms;
}
body[data-aos-delay="28100"][data-aos],
[data-aos][data-aos][data-aos-delay="28100"] {
  transition-delay: 28100ms;
}
body[data-aos-delay="28150"][data-aos],
[data-aos][data-aos][data-aos-delay="28150"] {
  transition-delay: 28150ms;
}
body[data-aos-delay="28200"][data-aos],
[data-aos][data-aos][data-aos-delay="28200"] {
  transition-delay: 28200ms;
}
body[data-aos-delay="28250"][data-aos],
[data-aos][data-aos][data-aos-delay="28250"] {
  transition-delay: 28250ms;
}
body[data-aos-delay="28300"][data-aos],
[data-aos][data-aos][data-aos-delay="28300"] {
  transition-delay: 28300ms;
}
body[data-aos-delay="28350"][data-aos],
[data-aos][data-aos][data-aos-delay="28350"] {
  transition-delay: 28350ms;
}
body[data-aos-delay="28400"][data-aos],
[data-aos][data-aos][data-aos-delay="28400"] {
  transition-delay: 28400ms;
}
body[data-aos-delay="28450"][data-aos],
[data-aos][data-aos][data-aos-delay="28450"] {
  transition-delay: 28450ms;
}
body[data-aos-delay="28500"][data-aos],
[data-aos][data-aos][data-aos-delay="28500"] {
  transition-delay: 28500ms;
}
body[data-aos-delay="28550"][data-aos],
[data-aos][data-aos][data-aos-delay="28550"] {
  transition-delay: 28550ms;
}
body[data-aos-delay="28600"][data-aos],
[data-aos][data-aos][data-aos-delay="28600"] {
  transition-delay: 28600ms;
}
body[data-aos-delay="28650"][data-aos],
[data-aos][data-aos][data-aos-delay="28650"] {
  transition-delay: 28650ms;
}
body[data-aos-delay="28700"][data-aos],
[data-aos][data-aos][data-aos-delay="28700"] {
  transition-delay: 28700ms;
}
body[data-aos-delay="28750"][data-aos],
[data-aos][data-aos][data-aos-delay="28750"] {
  transition-delay: 28750ms;
}
body[data-aos-delay="28800"][data-aos],
[data-aos][data-aos][data-aos-delay="28800"] {
  transition-delay: 28800ms;
}
body[data-aos-delay="28850"][data-aos],
[data-aos][data-aos][data-aos-delay="28850"] {
  transition-delay: 28850ms;
}
body[data-aos-delay="28900"][data-aos],
[data-aos][data-aos][data-aos-delay="28900"] {
  transition-delay: 28900ms;
}
body[data-aos-delay="28950"][data-aos],
[data-aos][data-aos][data-aos-delay="28950"] {
  transition-delay: 28950ms;
}
body[data-aos-delay="29000"][data-aos],
[data-aos][data-aos][data-aos-delay="29000"] {
  transition-delay: 29000ms;
}
body[data-aos-delay="29050"][data-aos],
[data-aos][data-aos][data-aos-delay="29050"] {
  transition-delay: 29050ms;
}
body[data-aos-delay="29100"][data-aos],
[data-aos][data-aos][data-aos-delay="29100"] {
  transition-delay: 29100ms;
}
body[data-aos-delay="29150"][data-aos],
[data-aos][data-aos][data-aos-delay="29150"] {
  transition-delay: 29150ms;
}
body[data-aos-delay="29200"][data-aos],
[data-aos][data-aos][data-aos-delay="29200"] {
  transition-delay: 29200ms;
}
body[data-aos-delay="29250"][data-aos],
[data-aos][data-aos][data-aos-delay="29250"] {
  transition-delay: 29250ms;
}
body[data-aos-delay="29300"][data-aos],
[data-aos][data-aos][data-aos-delay="29300"] {
  transition-delay: 29300ms;
}
body[data-aos-delay="29350"][data-aos],
[data-aos][data-aos][data-aos-delay="29350"] {
  transition-delay: 29350ms;
}
body[data-aos-delay="29400"][data-aos],
[data-aos][data-aos][data-aos-delay="29400"] {
  transition-delay: 29400ms;
}
body[data-aos-delay="29450"][data-aos],
[data-aos][data-aos][data-aos-delay="29450"] {
  transition-delay: 29450ms;
}
body[data-aos-delay="29500"][data-aos],
[data-aos][data-aos][data-aos-delay="29500"] {
  transition-delay: 29500ms;
}
body[data-aos-delay="29550"][data-aos],
[data-aos][data-aos][data-aos-delay="29550"] {
  transition-delay: 29550ms;
}
body[data-aos-delay="29600"][data-aos],
[data-aos][data-aos][data-aos-delay="29600"] {
  transition-delay: 29600ms;
}
body[data-aos-delay="29650"][data-aos],
[data-aos][data-aos][data-aos-delay="29650"] {
  transition-delay: 29650ms;
}
body[data-aos-delay="29700"][data-aos],
[data-aos][data-aos][data-aos-delay="29700"] {
  transition-delay: 29700ms;
}
body[data-aos-delay="29750"][data-aos],
[data-aos][data-aos][data-aos-delay="29750"] {
  transition-delay: 29750ms;
}
body[data-aos-delay="29800"][data-aos],
[data-aos][data-aos][data-aos-delay="29800"] {
  transition-delay: 29800ms;
}
body[data-aos-delay="29850"][data-aos],
[data-aos][data-aos][data-aos-delay="29850"] {
  transition-delay: 29850ms;
}
body[data-aos-delay="29900"][data-aos],
[data-aos][data-aos][data-aos-delay="29900"] {
  transition-delay: 29900ms;
}
body[data-aos-delay="29950"][data-aos],
[data-aos][data-aos][data-aos-delay="29950"] {
  transition-delay: 29950ms;
}
body[data-aos-delay="30000"][data-aos],
[data-aos][data-aos][data-aos-delay="30000"] {
  transition-delay: 30000ms;
}
body[data-aos-delay="30050"][data-aos],
[data-aos][data-aos][data-aos-delay="30050"] {
  transition-delay: 30050ms;
}
body[data-aos-delay="30100"][data-aos],
[data-aos][data-aos][data-aos-delay="30100"] {
  transition-delay: 30100ms;
}
body[data-aos-delay="30150"][data-aos],
[data-aos][data-aos][data-aos-delay="30150"] {
  transition-delay: 30150ms;
}
body[data-aos-delay="30200"][data-aos],
[data-aos][data-aos][data-aos-delay="30200"] {
  transition-delay: 30200ms;
}
body[data-aos-delay="30250"][data-aos],
[data-aos][data-aos][data-aos-delay="30250"] {
  transition-delay: 30250ms;
}
body[data-aos-delay="30300"][data-aos],
[data-aos][data-aos][data-aos-delay="30300"] {
  transition-delay: 30300ms;
}
body[data-aos-delay="30350"][data-aos],
[data-aos][data-aos][data-aos-delay="30350"] {
  transition-delay: 30350ms;
}
body[data-aos-delay="30400"][data-aos],
[data-aos][data-aos][data-aos-delay="30400"] {
  transition-delay: 30400ms;
}
body[data-aos-delay="30450"][data-aos],
[data-aos][data-aos][data-aos-delay="30450"] {
  transition-delay: 30450ms;
}
body[data-aos-delay="30500"][data-aos],
[data-aos][data-aos][data-aos-delay="30500"] {
  transition-delay: 30500ms;
}
body[data-aos-delay="30550"][data-aos],
[data-aos][data-aos][data-aos-delay="30550"] {
  transition-delay: 30550ms;
}
body[data-aos-delay="30600"][data-aos],
[data-aos][data-aos][data-aos-delay="30600"] {
  transition-delay: 30600ms;
}
body[data-aos-delay="30650"][data-aos],
[data-aos][data-aos][data-aos-delay="30650"] {
  transition-delay: 30650ms;
}
body[data-aos-delay="30700"][data-aos],
[data-aos][data-aos][data-aos-delay="30700"] {
  transition-delay: 30700ms;
}
body[data-aos-delay="30750"][data-aos],
[data-aos][data-aos][data-aos-delay="30750"] {
  transition-delay: 30750ms;
}
body[data-aos-delay="30800"][data-aos],
[data-aos][data-aos][data-aos-delay="30800"] {
  transition-delay: 30800ms;
}
body[data-aos-delay="30850"][data-aos],
[data-aos][data-aos][data-aos-delay="30850"] {
  transition-delay: 30850ms;
}
body[data-aos-delay="30900"][data-aos],
[data-aos][data-aos][data-aos-delay="30900"] {
  transition-delay: 30900ms;
}
body[data-aos-delay="30950"][data-aos],
[data-aos][data-aos][data-aos-delay="30950"] {
  transition-delay: 30950ms;
}
body[data-aos-delay="31000"][data-aos],
[data-aos][data-aos][data-aos-delay="31000"] {
  transition-delay: 31000ms;
}
body[data-aos-delay="31050"][data-aos],
[data-aos][data-aos][data-aos-delay="31050"] {
  transition-delay: 31050ms;
}
body[data-aos-delay="31100"][data-aos],
[data-aos][data-aos][data-aos-delay="31100"] {
  transition-delay: 31100ms;
}
body[data-aos-delay="31150"][data-aos],
[data-aos][data-aos][data-aos-delay="31150"] {
  transition-delay: 31150ms;
}
body[data-aos-delay="31200"][data-aos],
[data-aos][data-aos][data-aos-delay="31200"] {
  transition-delay: 31200ms;
}
body[data-aos-delay="31250"][data-aos],
[data-aos][data-aos][data-aos-delay="31250"] {
  transition-delay: 31250ms;
}
body[data-aos-delay="31300"][data-aos],
[data-aos][data-aos][data-aos-delay="31300"] {
  transition-delay: 31300ms;
}
body[data-aos-delay="31350"][data-aos],
[data-aos][data-aos][data-aos-delay="31350"] {
  transition-delay: 31350ms;
}
body[data-aos-delay="31400"][data-aos],
[data-aos][data-aos][data-aos-delay="31400"] {
  transition-delay: 31400ms;
}
body[data-aos-delay="31450"][data-aos],
[data-aos][data-aos][data-aos-delay="31450"] {
  transition-delay: 31450ms;
}
body[data-aos-delay="31500"][data-aos],
[data-aos][data-aos][data-aos-delay="31500"] {
  transition-delay: 31500ms;
}
body[data-aos-delay="31550"][data-aos],
[data-aos][data-aos][data-aos-delay="31550"] {
  transition-delay: 31550ms;
}
body[data-aos-delay="31600"][data-aos],
[data-aos][data-aos][data-aos-delay="31600"] {
  transition-delay: 31600ms;
}
body[data-aos-delay="31650"][data-aos],
[data-aos][data-aos][data-aos-delay="31650"] {
  transition-delay: 31650ms;
}
body[data-aos-delay="31700"][data-aos],
[data-aos][data-aos][data-aos-delay="31700"] {
  transition-delay: 31700ms;
}
body[data-aos-delay="31750"][data-aos],
[data-aos][data-aos][data-aos-delay="31750"] {
  transition-delay: 31750ms;
}
body[data-aos-delay="31800"][data-aos],
[data-aos][data-aos][data-aos-delay="31800"] {
  transition-delay: 31800ms;
}
body[data-aos-delay="31850"][data-aos],
[data-aos][data-aos][data-aos-delay="31850"] {
  transition-delay: 31850ms;
}
body[data-aos-delay="31900"][data-aos],
[data-aos][data-aos][data-aos-delay="31900"] {
  transition-delay: 31900ms;
}
body[data-aos-delay="31950"][data-aos],
[data-aos][data-aos][data-aos-delay="31950"] {
  transition-delay: 31950ms;
}
body[data-aos-delay="32000"][data-aos],
[data-aos][data-aos][data-aos-delay="32000"] {
  transition-delay: 32000ms;
}
body[data-aos-delay="32050"][data-aos],
[data-aos][data-aos][data-aos-delay="32050"] {
  transition-delay: 32050ms;
}
body[data-aos-delay="32100"][data-aos],
[data-aos][data-aos][data-aos-delay="32100"] {
  transition-delay: 32100ms;
}
body[data-aos-delay="32150"][data-aos],
[data-aos][data-aos][data-aos-delay="32150"] {
  transition-delay: 32150ms;
}
body[data-aos-delay="32200"][data-aos],
[data-aos][data-aos][data-aos-delay="32200"] {
  transition-delay: 32200ms;
}
body[data-aos-delay="32250"][data-aos],
[data-aos][data-aos][data-aos-delay="32250"] {
  transition-delay: 32250ms;
}
body[data-aos-delay="32300"][data-aos],
[data-aos][data-aos][data-aos-delay="32300"] {
  transition-delay: 32300ms;
}
body[data-aos-delay="32350"][data-aos],
[data-aos][data-aos][data-aos-delay="32350"] {
  transition-delay: 32350ms;
}
body[data-aos-delay="32400"][data-aos],
[data-aos][data-aos][data-aos-delay="32400"] {
  transition-delay: 32400ms;
}
body[data-aos-delay="32450"][data-aos],
[data-aos][data-aos][data-aos-delay="32450"] {
  transition-delay: 32450ms;
}
body[data-aos-delay="32500"][data-aos],
[data-aos][data-aos][data-aos-delay="32500"] {
  transition-delay: 32500ms;
}
body[data-aos-delay="32550"][data-aos],
[data-aos][data-aos][data-aos-delay="32550"] {
  transition-delay: 32550ms;
}
body[data-aos-delay="32600"][data-aos],
[data-aos][data-aos][data-aos-delay="32600"] {
  transition-delay: 32600ms;
}
body[data-aos-delay="32650"][data-aos],
[data-aos][data-aos][data-aos-delay="32650"] {
  transition-delay: 32650ms;
}
body[data-aos-delay="32700"][data-aos],
[data-aos][data-aos][data-aos-delay="32700"] {
  transition-delay: 32700ms;
}
body[data-aos-delay="32750"][data-aos],
[data-aos][data-aos][data-aos-delay="32750"] {
  transition-delay: 32750ms;
}
body[data-aos-delay="32800"][data-aos],
[data-aos][data-aos][data-aos-delay="32800"] {
  transition-delay: 32800ms;
}
body[data-aos-delay="32850"][data-aos],
[data-aos][data-aos][data-aos-delay="32850"] {
  transition-delay: 32850ms;
}
body[data-aos-delay="32900"][data-aos],
[data-aos][data-aos][data-aos-delay="32900"] {
  transition-delay: 32900ms;
}
body[data-aos-delay="32950"][data-aos],
[data-aos][data-aos][data-aos-delay="32950"] {
  transition-delay: 32950ms;
}
body[data-aos-delay="33000"][data-aos],
[data-aos][data-aos][data-aos-delay="33000"] {
  transition-delay: 33000ms;
}
body[data-aos-delay="33050"][data-aos],
[data-aos][data-aos][data-aos-delay="33050"] {
  transition-delay: 33050ms;
}
body[data-aos-delay="33100"][data-aos],
[data-aos][data-aos][data-aos-delay="33100"] {
  transition-delay: 33100ms;
}
body[data-aos-delay="33150"][data-aos],
[data-aos][data-aos][data-aos-delay="33150"] {
  transition-delay: 33150ms;
}
body[data-aos-delay="33200"][data-aos],
[data-aos][data-aos][data-aos-delay="33200"] {
  transition-delay: 33200ms;
}
body[data-aos-delay="33250"][data-aos],
[data-aos][data-aos][data-aos-delay="33250"] {
  transition-delay: 33250ms;
}
body[data-aos-delay="33300"][data-aos],
[data-aos][data-aos][data-aos-delay="33300"] {
  transition-delay: 33300ms;
}
body[data-aos-delay="33350"][data-aos],
[data-aos][data-aos][data-aos-delay="33350"] {
  transition-delay: 33350ms;
}
body[data-aos-delay="33400"][data-aos],
[data-aos][data-aos][data-aos-delay="33400"] {
  transition-delay: 33400ms;
}
body[data-aos-delay="33450"][data-aos],
[data-aos][data-aos][data-aos-delay="33450"] {
  transition-delay: 33450ms;
}
body[data-aos-delay="33500"][data-aos],
[data-aos][data-aos][data-aos-delay="33500"] {
  transition-delay: 33500ms;
}
body[data-aos-delay="33550"][data-aos],
[data-aos][data-aos][data-aos-delay="33550"] {
  transition-delay: 33550ms;
}
body[data-aos-delay="33600"][data-aos],
[data-aos][data-aos][data-aos-delay="33600"] {
  transition-delay: 33600ms;
}
body[data-aos-delay="33650"][data-aos],
[data-aos][data-aos][data-aos-delay="33650"] {
  transition-delay: 33650ms;
}
body[data-aos-delay="33700"][data-aos],
[data-aos][data-aos][data-aos-delay="33700"] {
  transition-delay: 33700ms;
}
body[data-aos-delay="33750"][data-aos],
[data-aos][data-aos][data-aos-delay="33750"] {
  transition-delay: 33750ms;
}
body[data-aos-delay="33800"][data-aos],
[data-aos][data-aos][data-aos-delay="33800"] {
  transition-delay: 33800ms;
}
body[data-aos-delay="33850"][data-aos],
[data-aos][data-aos][data-aos-delay="33850"] {
  transition-delay: 33850ms;
}
body[data-aos-delay="33900"][data-aos],
[data-aos][data-aos][data-aos-delay="33900"] {
  transition-delay: 33900ms;
}
body[data-aos-delay="33950"][data-aos],
[data-aos][data-aos][data-aos-delay="33950"] {
  transition-delay: 33950ms;
}
body[data-aos-delay="34000"][data-aos],
[data-aos][data-aos][data-aos-delay="34000"] {
  transition-delay: 34000ms;
}
body[data-aos-delay="34050"][data-aos],
[data-aos][data-aos][data-aos-delay="34050"] {
  transition-delay: 34050ms;
}
body[data-aos-delay="34100"][data-aos],
[data-aos][data-aos][data-aos-delay="34100"] {
  transition-delay: 34100ms;
}
body[data-aos-delay="34150"][data-aos],
[data-aos][data-aos][data-aos-delay="34150"] {
  transition-delay: 34150ms;
}
body[data-aos-delay="34200"][data-aos],
[data-aos][data-aos][data-aos-delay="34200"] {
  transition-delay: 34200ms;
}
body[data-aos-delay="34250"][data-aos],
[data-aos][data-aos][data-aos-delay="34250"] {
  transition-delay: 34250ms;
}
body[data-aos-delay="34300"][data-aos],
[data-aos][data-aos][data-aos-delay="34300"] {
  transition-delay: 34300ms;
}
body[data-aos-delay="34350"][data-aos],
[data-aos][data-aos][data-aos-delay="34350"] {
  transition-delay: 34350ms;
}
body[data-aos-delay="34400"][data-aos],
[data-aos][data-aos][data-aos-delay="34400"] {
  transition-delay: 34400ms;
}
body[data-aos-delay="34450"][data-aos],
[data-aos][data-aos][data-aos-delay="34450"] {
  transition-delay: 34450ms;
}
body[data-aos-delay="34500"][data-aos],
[data-aos][data-aos][data-aos-delay="34500"] {
  transition-delay: 34500ms;
}
body[data-aos-delay="34550"][data-aos],
[data-aos][data-aos][data-aos-delay="34550"] {
  transition-delay: 34550ms;
}
body[data-aos-delay="34600"][data-aos],
[data-aos][data-aos][data-aos-delay="34600"] {
  transition-delay: 34600ms;
}
body[data-aos-delay="34650"][data-aos],
[data-aos][data-aos][data-aos-delay="34650"] {
  transition-delay: 34650ms;
}
body[data-aos-delay="34700"][data-aos],
[data-aos][data-aos][data-aos-delay="34700"] {
  transition-delay: 34700ms;
}
body[data-aos-delay="34750"][data-aos],
[data-aos][data-aos][data-aos-delay="34750"] {
  transition-delay: 34750ms;
}
body[data-aos-delay="34800"][data-aos],
[data-aos][data-aos][data-aos-delay="34800"] {
  transition-delay: 34800ms;
}
body[data-aos-delay="34850"][data-aos],
[data-aos][data-aos][data-aos-delay="34850"] {
  transition-delay: 34850ms;
}
body[data-aos-delay="34900"][data-aos],
[data-aos][data-aos][data-aos-delay="34900"] {
  transition-delay: 34900ms;
}
body[data-aos-delay="34950"][data-aos],
[data-aos][data-aos][data-aos-delay="34950"] {
  transition-delay: 34950ms;
}
body[data-aos-delay="35000"][data-aos],
[data-aos][data-aos][data-aos-delay="35000"] {
  transition-delay: 35000ms;
}
body[data-aos-delay="35050"][data-aos],
[data-aos][data-aos][data-aos-delay="35050"] {
  transition-delay: 35050ms;
}
body[data-aos-delay="35100"][data-aos],
[data-aos][data-aos][data-aos-delay="35100"] {
  transition-delay: 35100ms;
}
body[data-aos-delay="35150"][data-aos],
[data-aos][data-aos][data-aos-delay="35150"] {
  transition-delay: 35150ms;
}
body[data-aos-delay="35200"][data-aos],
[data-aos][data-aos][data-aos-delay="35200"] {
  transition-delay: 35200ms;
}
body[data-aos-delay="35250"][data-aos],
[data-aos][data-aos][data-aos-delay="35250"] {
  transition-delay: 35250ms;
}
body[data-aos-delay="35300"][data-aos],
[data-aos][data-aos][data-aos-delay="35300"] {
  transition-delay: 35300ms;
}
body[data-aos-delay="35350"][data-aos],
[data-aos][data-aos][data-aos-delay="35350"] {
  transition-delay: 35350ms;
}
body[data-aos-delay="35400"][data-aos],
[data-aos][data-aos][data-aos-delay="35400"] {
  transition-delay: 35400ms;
}
body[data-aos-delay="35450"][data-aos],
[data-aos][data-aos][data-aos-delay="35450"] {
  transition-delay: 35450ms;
}
body[data-aos-delay="35500"][data-aos],
[data-aos][data-aos][data-aos-delay="35500"] {
  transition-delay: 35500ms;
}
body[data-aos-delay="35550"][data-aos],
[data-aos][data-aos][data-aos-delay="35550"] {
  transition-delay: 35550ms;
}
body[data-aos-delay="35600"][data-aos],
[data-aos][data-aos][data-aos-delay="35600"] {
  transition-delay: 35600ms;
}
body[data-aos-delay="35650"][data-aos],
[data-aos][data-aos][data-aos-delay="35650"] {
  transition-delay: 35650ms;
}
body[data-aos-delay="35700"][data-aos],
[data-aos][data-aos][data-aos-delay="35700"] {
  transition-delay: 35700ms;
}
body[data-aos-delay="35750"][data-aos],
[data-aos][data-aos][data-aos-delay="35750"] {
  transition-delay: 35750ms;
}
body[data-aos-delay="35800"][data-aos],
[data-aos][data-aos][data-aos-delay="35800"] {
  transition-delay: 35800ms;
}
body[data-aos-delay="35850"][data-aos],
[data-aos][data-aos][data-aos-delay="35850"] {
  transition-delay: 35850ms;
}
body[data-aos-delay="35900"][data-aos],
[data-aos][data-aos][data-aos-delay="35900"] {
  transition-delay: 35900ms;
}
body[data-aos-delay="35950"][data-aos],
[data-aos][data-aos][data-aos-delay="35950"] {
  transition-delay: 35950ms;
}
body[data-aos-delay="36000"][data-aos],
[data-aos][data-aos][data-aos-delay="36000"] {
  transition-delay: 36000ms;
}
body[data-aos-delay="36050"][data-aos],
[data-aos][data-aos][data-aos-delay="36050"] {
  transition-delay: 36050ms;
}
body[data-aos-delay="36100"][data-aos],
[data-aos][data-aos][data-aos-delay="36100"] {
  transition-delay: 36100ms;
}
body[data-aos-delay="36150"][data-aos],
[data-aos][data-aos][data-aos-delay="36150"] {
  transition-delay: 36150ms;
}
body[data-aos-delay="36200"][data-aos],
[data-aos][data-aos][data-aos-delay="36200"] {
  transition-delay: 36200ms;
}
body[data-aos-delay="36250"][data-aos],
[data-aos][data-aos][data-aos-delay="36250"] {
  transition-delay: 36250ms;
}
body[data-aos-delay="36300"][data-aos],
[data-aos][data-aos][data-aos-delay="36300"] {
  transition-delay: 36300ms;
}
body[data-aos-delay="36350"][data-aos],
[data-aos][data-aos][data-aos-delay="36350"] {
  transition-delay: 36350ms;
}
body[data-aos-delay="36400"][data-aos],
[data-aos][data-aos][data-aos-delay="36400"] {
  transition-delay: 36400ms;
}
body[data-aos-delay="36450"][data-aos],
[data-aos][data-aos][data-aos-delay="36450"] {
  transition-delay: 36450ms;
}
body[data-aos-delay="36500"][data-aos],
[data-aos][data-aos][data-aos-delay="36500"] {
  transition-delay: 36500ms;
}
body[data-aos-delay="36550"][data-aos],
[data-aos][data-aos][data-aos-delay="36550"] {
  transition-delay: 36550ms;
}
body[data-aos-delay="36600"][data-aos],
[data-aos][data-aos][data-aos-delay="36600"] {
  transition-delay: 36600ms;
}
body[data-aos-delay="36650"][data-aos],
[data-aos][data-aos][data-aos-delay="36650"] {
  transition-delay: 36650ms;
}
body[data-aos-delay="36700"][data-aos],
[data-aos][data-aos][data-aos-delay="36700"] {
  transition-delay: 36700ms;
}
body[data-aos-delay="36750"][data-aos],
[data-aos][data-aos][data-aos-delay="36750"] {
  transition-delay: 36750ms;
}
body[data-aos-delay="36800"][data-aos],
[data-aos][data-aos][data-aos-delay="36800"] {
  transition-delay: 36800ms;
}
body[data-aos-delay="36850"][data-aos],
[data-aos][data-aos][data-aos-delay="36850"] {
  transition-delay: 36850ms;
}
body[data-aos-delay="36900"][data-aos],
[data-aos][data-aos][data-aos-delay="36900"] {
  transition-delay: 36900ms;
}
body[data-aos-delay="36950"][data-aos],
[data-aos][data-aos][data-aos-delay="36950"] {
  transition-delay: 36950ms;
}
body[data-aos-delay="37000"][data-aos],
[data-aos][data-aos][data-aos-delay="37000"] {
  transition-delay: 37000ms;
}
body[data-aos-delay="37050"][data-aos],
[data-aos][data-aos][data-aos-delay="37050"] {
  transition-delay: 37050ms;
}
body[data-aos-delay="37100"][data-aos],
[data-aos][data-aos][data-aos-delay="37100"] {
  transition-delay: 37100ms;
}
body[data-aos-delay="37150"][data-aos],
[data-aos][data-aos][data-aos-delay="37150"] {
  transition-delay: 37150ms;
}
body[data-aos-delay="37200"][data-aos],
[data-aos][data-aos][data-aos-delay="37200"] {
  transition-delay: 37200ms;
}
body[data-aos-delay="37250"][data-aos],
[data-aos][data-aos][data-aos-delay="37250"] {
  transition-delay: 37250ms;
}
body[data-aos-delay="37300"][data-aos],
[data-aos][data-aos][data-aos-delay="37300"] {
  transition-delay: 37300ms;
}
body[data-aos-delay="37350"][data-aos],
[data-aos][data-aos][data-aos-delay="37350"] {
  transition-delay: 37350ms;
}
body[data-aos-delay="37400"][data-aos],
[data-aos][data-aos][data-aos-delay="37400"] {
  transition-delay: 37400ms;
}
body[data-aos-delay="37450"][data-aos],
[data-aos][data-aos][data-aos-delay="37450"] {
  transition-delay: 37450ms;
}
body[data-aos-delay="37500"][data-aos],
[data-aos][data-aos][data-aos-delay="37500"] {
  transition-delay: 37500ms;
}
body[data-aos-delay="37550"][data-aos],
[data-aos][data-aos][data-aos-delay="37550"] {
  transition-delay: 37550ms;
}
body[data-aos-delay="37600"][data-aos],
[data-aos][data-aos][data-aos-delay="37600"] {
  transition-delay: 37600ms;
}
body[data-aos-delay="37650"][data-aos],
[data-aos][data-aos][data-aos-delay="37650"] {
  transition-delay: 37650ms;
}
body[data-aos-delay="37700"][data-aos],
[data-aos][data-aos][data-aos-delay="37700"] {
  transition-delay: 37700ms;
}
body[data-aos-delay="37750"][data-aos],
[data-aos][data-aos][data-aos-delay="37750"] {
  transition-delay: 37750ms;
}
body[data-aos-delay="37800"][data-aos],
[data-aos][data-aos][data-aos-delay="37800"] {
  transition-delay: 37800ms;
}
body[data-aos-delay="37850"][data-aos],
[data-aos][data-aos][data-aos-delay="37850"] {
  transition-delay: 37850ms;
}
body[data-aos-delay="37900"][data-aos],
[data-aos][data-aos][data-aos-delay="37900"] {
  transition-delay: 37900ms;
}
body[data-aos-delay="37950"][data-aos],
[data-aos][data-aos][data-aos-delay="37950"] {
  transition-delay: 37950ms;
}
body[data-aos-delay="38000"][data-aos],
[data-aos][data-aos][data-aos-delay="38000"] {
  transition-delay: 38000ms;
}
body[data-aos-delay="38050"][data-aos],
[data-aos][data-aos][data-aos-delay="38050"] {
  transition-delay: 38050ms;
}
body[data-aos-delay="38100"][data-aos],
[data-aos][data-aos][data-aos-delay="38100"] {
  transition-delay: 38100ms;
}
body[data-aos-delay="38150"][data-aos],
[data-aos][data-aos][data-aos-delay="38150"] {
  transition-delay: 38150ms;
}
body[data-aos-delay="38200"][data-aos],
[data-aos][data-aos][data-aos-delay="38200"] {
  transition-delay: 38200ms;
}
body[data-aos-delay="38250"][data-aos],
[data-aos][data-aos][data-aos-delay="38250"] {
  transition-delay: 38250ms;
}
body[data-aos-delay="38300"][data-aos],
[data-aos][data-aos][data-aos-delay="38300"] {
  transition-delay: 38300ms;
}
body[data-aos-delay="38350"][data-aos],
[data-aos][data-aos][data-aos-delay="38350"] {
  transition-delay: 38350ms;
}
body[data-aos-delay="38400"][data-aos],
[data-aos][data-aos][data-aos-delay="38400"] {
  transition-delay: 38400ms;
}
body[data-aos-delay="38450"][data-aos],
[data-aos][data-aos][data-aos-delay="38450"] {
  transition-delay: 38450ms;
}
body[data-aos-delay="38500"][data-aos],
[data-aos][data-aos][data-aos-delay="38500"] {
  transition-delay: 38500ms;
}
body[data-aos-delay="38550"][data-aos],
[data-aos][data-aos][data-aos-delay="38550"] {
  transition-delay: 38550ms;
}
body[data-aos-delay="38600"][data-aos],
[data-aos][data-aos][data-aos-delay="38600"] {
  transition-delay: 38600ms;
}
body[data-aos-delay="38650"][data-aos],
[data-aos][data-aos][data-aos-delay="38650"] {
  transition-delay: 38650ms;
}
body[data-aos-delay="38700"][data-aos],
[data-aos][data-aos][data-aos-delay="38700"] {
  transition-delay: 38700ms;
}
body[data-aos-delay="38750"][data-aos],
[data-aos][data-aos][data-aos-delay="38750"] {
  transition-delay: 38750ms;
}
body[data-aos-delay="38800"][data-aos],
[data-aos][data-aos][data-aos-delay="38800"] {
  transition-delay: 38800ms;
}
body[data-aos-delay="38850"][data-aos],
[data-aos][data-aos][data-aos-delay="38850"] {
  transition-delay: 38850ms;
}
body[data-aos-delay="38900"][data-aos],
[data-aos][data-aos][data-aos-delay="38900"] {
  transition-delay: 38900ms;
}
body[data-aos-delay="38950"][data-aos],
[data-aos][data-aos][data-aos-delay="38950"] {
  transition-delay: 38950ms;
}
body[data-aos-delay="39000"][data-aos],
[data-aos][data-aos][data-aos-delay="39000"] {
  transition-delay: 39000ms;
}
body[data-aos-delay="39050"][data-aos],
[data-aos][data-aos][data-aos-delay="39050"] {
  transition-delay: 39050ms;
}
body[data-aos-delay="39100"][data-aos],
[data-aos][data-aos][data-aos-delay="39100"] {
  transition-delay: 39100ms;
}
body[data-aos-delay="39150"][data-aos],
[data-aos][data-aos][data-aos-delay="39150"] {
  transition-delay: 39150ms;
}
body[data-aos-delay="39200"][data-aos],
[data-aos][data-aos][data-aos-delay="39200"] {
  transition-delay: 39200ms;
}
body[data-aos-delay="39250"][data-aos],
[data-aos][data-aos][data-aos-delay="39250"] {
  transition-delay: 39250ms;
}
body[data-aos-delay="39300"][data-aos],
[data-aos][data-aos][data-aos-delay="39300"] {
  transition-delay: 39300ms;
}
body[data-aos-delay="39350"][data-aos],
[data-aos][data-aos][data-aos-delay="39350"] {
  transition-delay: 39350ms;
}
body[data-aos-delay="39400"][data-aos],
[data-aos][data-aos][data-aos-delay="39400"] {
  transition-delay: 39400ms;
}
body[data-aos-delay="39450"][data-aos],
[data-aos][data-aos][data-aos-delay="39450"] {
  transition-delay: 39450ms;
}
body[data-aos-delay="39500"][data-aos],
[data-aos][data-aos][data-aos-delay="39500"] {
  transition-delay: 39500ms;
}
body[data-aos-delay="39550"][data-aos],
[data-aos][data-aos][data-aos-delay="39550"] {
  transition-delay: 39550ms;
}
body[data-aos-delay="39600"][data-aos],
[data-aos][data-aos][data-aos-delay="39600"] {
  transition-delay: 39600ms;
}
body[data-aos-delay="39650"][data-aos],
[data-aos][data-aos][data-aos-delay="39650"] {
  transition-delay: 39650ms;
}
body[data-aos-delay="39700"][data-aos],
[data-aos][data-aos][data-aos-delay="39700"] {
  transition-delay: 39700ms;
}
body[data-aos-delay="39750"][data-aos],
[data-aos][data-aos][data-aos-delay="39750"] {
  transition-delay: 39750ms;
}
body[data-aos-delay="39800"][data-aos],
[data-aos][data-aos][data-aos-delay="39800"] {
  transition-delay: 39800ms;
}
body[data-aos-delay="39850"][data-aos],
[data-aos][data-aos][data-aos-delay="39850"] {
  transition-delay: 39850ms;
}
body[data-aos-delay="39900"][data-aos],
[data-aos][data-aos][data-aos-delay="39900"] {
  transition-delay: 39900ms;
}
body[data-aos-delay="39950"][data-aos],
[data-aos][data-aos][data-aos-delay="39950"] {
  transition-delay: 39950ms;
}
body[data-aos-delay="40000"][data-aos],
[data-aos][data-aos][data-aos-delay="40000"] {
  transition-delay: 40000ms;
}
body[data-aos-delay="40050"][data-aos],
[data-aos][data-aos][data-aos-delay="40050"] {
  transition-delay: 40050ms;
}
body[data-aos-delay="40100"][data-aos],
[data-aos][data-aos][data-aos-delay="40100"] {
  transition-delay: 40100ms;
}
body[data-aos-delay="40150"][data-aos],
[data-aos][data-aos][data-aos-delay="40150"] {
  transition-delay: 40150ms;
}
body[data-aos-delay="40200"][data-aos],
[data-aos][data-aos][data-aos-delay="40200"] {
  transition-delay: 40200ms;
}
body[data-aos-delay="40250"][data-aos],
[data-aos][data-aos][data-aos-delay="40250"] {
  transition-delay: 40250ms;
}
body[data-aos-delay="40300"][data-aos],
[data-aos][data-aos][data-aos-delay="40300"] {
  transition-delay: 40300ms;
}
body[data-aos-delay="40350"][data-aos],
[data-aos][data-aos][data-aos-delay="40350"] {
  transition-delay: 40350ms;
}
body[data-aos-delay="40400"][data-aos],
[data-aos][data-aos][data-aos-delay="40400"] {
  transition-delay: 40400ms;
}
body[data-aos-delay="40450"][data-aos],
[data-aos][data-aos][data-aos-delay="40450"] {
  transition-delay: 40450ms;
}
body[data-aos-delay="40500"][data-aos],
[data-aos][data-aos][data-aos-delay="40500"] {
  transition-delay: 40500ms;
}
body[data-aos-delay="40550"][data-aos],
[data-aos][data-aos][data-aos-delay="40550"] {
  transition-delay: 40550ms;
}
body[data-aos-delay="40600"][data-aos],
[data-aos][data-aos][data-aos-delay="40600"] {
  transition-delay: 40600ms;
}
body[data-aos-delay="40650"][data-aos],
[data-aos][data-aos][data-aos-delay="40650"] {
  transition-delay: 40650ms;
}
body[data-aos-delay="40700"][data-aos],
[data-aos][data-aos][data-aos-delay="40700"] {
  transition-delay: 40700ms;
}
body[data-aos-delay="40750"][data-aos],
[data-aos][data-aos][data-aos-delay="40750"] {
  transition-delay: 40750ms;
}
body[data-aos-delay="40800"][data-aos],
[data-aos][data-aos][data-aos-delay="40800"] {
  transition-delay: 40800ms;
}
body[data-aos-delay="40850"][data-aos],
[data-aos][data-aos][data-aos-delay="40850"] {
  transition-delay: 40850ms;
}
body[data-aos-delay="40900"][data-aos],
[data-aos][data-aos][data-aos-delay="40900"] {
  transition-delay: 40900ms;
}
body[data-aos-delay="40950"][data-aos],
[data-aos][data-aos][data-aos-delay="40950"] {
  transition-delay: 40950ms;
}
body[data-aos-delay="41000"][data-aos],
[data-aos][data-aos][data-aos-delay="41000"] {
  transition-delay: 41000ms;
}
body[data-aos-delay="41050"][data-aos],
[data-aos][data-aos][data-aos-delay="41050"] {
  transition-delay: 41050ms;
}
body[data-aos-delay="41100"][data-aos],
[data-aos][data-aos][data-aos-delay="41100"] {
  transition-delay: 41100ms;
}
body[data-aos-delay="41150"][data-aos],
[data-aos][data-aos][data-aos-delay="41150"] {
  transition-delay: 41150ms;
}
body[data-aos-delay="41200"][data-aos],
[data-aos][data-aos][data-aos-delay="41200"] {
  transition-delay: 41200ms;
}
body[data-aos-delay="41250"][data-aos],
[data-aos][data-aos][data-aos-delay="41250"] {
  transition-delay: 41250ms;
}
body[data-aos-delay="41300"][data-aos],
[data-aos][data-aos][data-aos-delay="41300"] {
  transition-delay: 41300ms;
}
body[data-aos-delay="41350"][data-aos],
[data-aos][data-aos][data-aos-delay="41350"] {
  transition-delay: 41350ms;
}
body[data-aos-delay="41400"][data-aos],
[data-aos][data-aos][data-aos-delay="41400"] {
  transition-delay: 41400ms;
}
body[data-aos-delay="41450"][data-aos],
[data-aos][data-aos][data-aos-delay="41450"] {
  transition-delay: 41450ms;
}
body[data-aos-delay="41500"][data-aos],
[data-aos][data-aos][data-aos-delay="41500"] {
  transition-delay: 41500ms;
}
body[data-aos-delay="41550"][data-aos],
[data-aos][data-aos][data-aos-delay="41550"] {
  transition-delay: 41550ms;
}
body[data-aos-delay="41600"][data-aos],
[data-aos][data-aos][data-aos-delay="41600"] {
  transition-delay: 41600ms;
}
body[data-aos-delay="41650"][data-aos],
[data-aos][data-aos][data-aos-delay="41650"] {
  transition-delay: 41650ms;
}
body[data-aos-delay="41700"][data-aos],
[data-aos][data-aos][data-aos-delay="41700"] {
  transition-delay: 41700ms;
}
body[data-aos-delay="41750"][data-aos],
[data-aos][data-aos][data-aos-delay="41750"] {
  transition-delay: 41750ms;
}
body[data-aos-delay="41800"][data-aos],
[data-aos][data-aos][data-aos-delay="41800"] {
  transition-delay: 41800ms;
}
body[data-aos-delay="41850"][data-aos],
[data-aos][data-aos][data-aos-delay="41850"] {
  transition-delay: 41850ms;
}
body[data-aos-delay="41900"][data-aos],
[data-aos][data-aos][data-aos-delay="41900"] {
  transition-delay: 41900ms;
}
body[data-aos-delay="41950"][data-aos],
[data-aos][data-aos][data-aos-delay="41950"] {
  transition-delay: 41950ms;
}
body[data-aos-delay="42000"][data-aos],
[data-aos][data-aos][data-aos-delay="42000"] {
  transition-delay: 42000ms;
}
body[data-aos-delay="42050"][data-aos],
[data-aos][data-aos][data-aos-delay="42050"] {
  transition-delay: 42050ms;
}
body[data-aos-delay="42100"][data-aos],
[data-aos][data-aos][data-aos-delay="42100"] {
  transition-delay: 42100ms;
}
body[data-aos-delay="42150"][data-aos],
[data-aos][data-aos][data-aos-delay="42150"] {
  transition-delay: 42150ms;
}
body[data-aos-delay="42200"][data-aos],
[data-aos][data-aos][data-aos-delay="42200"] {
  transition-delay: 42200ms;
}
body[data-aos-delay="42250"][data-aos],
[data-aos][data-aos][data-aos-delay="42250"] {
  transition-delay: 42250ms;
}
body[data-aos-delay="42300"][data-aos],
[data-aos][data-aos][data-aos-delay="42300"] {
  transition-delay: 42300ms;
}
body[data-aos-delay="42350"][data-aos],
[data-aos][data-aos][data-aos-delay="42350"] {
  transition-delay: 42350ms;
}
body[data-aos-delay="42400"][data-aos],
[data-aos][data-aos][data-aos-delay="42400"] {
  transition-delay: 42400ms;
}
body[data-aos-delay="42450"][data-aos],
[data-aos][data-aos][data-aos-delay="42450"] {
  transition-delay: 42450ms;
}
body[data-aos-delay="42500"][data-aos],
[data-aos][data-aos][data-aos-delay="42500"] {
  transition-delay: 42500ms;
}
body[data-aos-delay="42550"][data-aos],
[data-aos][data-aos][data-aos-delay="42550"] {
  transition-delay: 42550ms;
}
body[data-aos-delay="42600"][data-aos],
[data-aos][data-aos][data-aos-delay="42600"] {
  transition-delay: 42600ms;
}
body[data-aos-delay="42650"][data-aos],
[data-aos][data-aos][data-aos-delay="42650"] {
  transition-delay: 42650ms;
}
body[data-aos-delay="42700"][data-aos],
[data-aos][data-aos][data-aos-delay="42700"] {
  transition-delay: 42700ms;
}
body[data-aos-delay="42750"][data-aos],
[data-aos][data-aos][data-aos-delay="42750"] {
  transition-delay: 42750ms;
}
body[data-aos-delay="42800"][data-aos],
[data-aos][data-aos][data-aos-delay="42800"] {
  transition-delay: 42800ms;
}
body[data-aos-delay="42850"][data-aos],
[data-aos][data-aos][data-aos-delay="42850"] {
  transition-delay: 42850ms;
}
body[data-aos-delay="42900"][data-aos],
[data-aos][data-aos][data-aos-delay="42900"] {
  transition-delay: 42900ms;
}
body[data-aos-delay="42950"][data-aos],
[data-aos][data-aos][data-aos-delay="42950"] {
  transition-delay: 42950ms;
}
body[data-aos-delay="43000"][data-aos],
[data-aos][data-aos][data-aos-delay="43000"] {
  transition-delay: 43000ms;
}
body[data-aos-delay="43050"][data-aos],
[data-aos][data-aos][data-aos-delay="43050"] {
  transition-delay: 43050ms;
}
body[data-aos-delay="43100"][data-aos],
[data-aos][data-aos][data-aos-delay="43100"] {
  transition-delay: 43100ms;
}
body[data-aos-delay="43150"][data-aos],
[data-aos][data-aos][data-aos-delay="43150"] {
  transition-delay: 43150ms;
}
body[data-aos-delay="43200"][data-aos],
[data-aos][data-aos][data-aos-delay="43200"] {
  transition-delay: 43200ms;
}
body[data-aos-delay="43250"][data-aos],
[data-aos][data-aos][data-aos-delay="43250"] {
  transition-delay: 43250ms;
}
body[data-aos-delay="43300"][data-aos],
[data-aos][data-aos][data-aos-delay="43300"] {
  transition-delay: 43300ms;
}
body[data-aos-delay="43350"][data-aos],
[data-aos][data-aos][data-aos-delay="43350"] {
  transition-delay: 43350ms;
}
body[data-aos-delay="43400"][data-aos],
[data-aos][data-aos][data-aos-delay="43400"] {
  transition-delay: 43400ms;
}
body[data-aos-delay="43450"][data-aos],
[data-aos][data-aos][data-aos-delay="43450"] {
  transition-delay: 43450ms;
}
body[data-aos-delay="43500"][data-aos],
[data-aos][data-aos][data-aos-delay="43500"] {
  transition-delay: 43500ms;
}
body[data-aos-delay="43550"][data-aos],
[data-aos][data-aos][data-aos-delay="43550"] {
  transition-delay: 43550ms;
}
body[data-aos-delay="43600"][data-aos],
[data-aos][data-aos][data-aos-delay="43600"] {
  transition-delay: 43600ms;
}
body[data-aos-delay="43650"][data-aos],
[data-aos][data-aos][data-aos-delay="43650"] {
  transition-delay: 43650ms;
}
body[data-aos-delay="43700"][data-aos],
[data-aos][data-aos][data-aos-delay="43700"] {
  transition-delay: 43700ms;
}
body[data-aos-delay="43750"][data-aos],
[data-aos][data-aos][data-aos-delay="43750"] {
  transition-delay: 43750ms;
}
body[data-aos-delay="43800"][data-aos],
[data-aos][data-aos][data-aos-delay="43800"] {
  transition-delay: 43800ms;
}
body[data-aos-delay="43850"][data-aos],
[data-aos][data-aos][data-aos-delay="43850"] {
  transition-delay: 43850ms;
}
body[data-aos-delay="43900"][data-aos],
[data-aos][data-aos][data-aos-delay="43900"] {
  transition-delay: 43900ms;
}
body[data-aos-delay="43950"][data-aos],
[data-aos][data-aos][data-aos-delay="43950"] {
  transition-delay: 43950ms;
}
body[data-aos-delay="44000"][data-aos],
[data-aos][data-aos][data-aos-delay="44000"] {
  transition-delay: 44000ms;
}
body[data-aos-delay="44050"][data-aos],
[data-aos][data-aos][data-aos-delay="44050"] {
  transition-delay: 44050ms;
}
body[data-aos-delay="44100"][data-aos],
[data-aos][data-aos][data-aos-delay="44100"] {
  transition-delay: 44100ms;
}
body[data-aos-delay="44150"][data-aos],
[data-aos][data-aos][data-aos-delay="44150"] {
  transition-delay: 44150ms;
}
body[data-aos-delay="44200"][data-aos],
[data-aos][data-aos][data-aos-delay="44200"] {
  transition-delay: 44200ms;
}
body[data-aos-delay="44250"][data-aos],
[data-aos][data-aos][data-aos-delay="44250"] {
  transition-delay: 44250ms;
}
body[data-aos-delay="44300"][data-aos],
[data-aos][data-aos][data-aos-delay="44300"] {
  transition-delay: 44300ms;
}
body[data-aos-delay="44350"][data-aos],
[data-aos][data-aos][data-aos-delay="44350"] {
  transition-delay: 44350ms;
}
body[data-aos-delay="44400"][data-aos],
[data-aos][data-aos][data-aos-delay="44400"] {
  transition-delay: 44400ms;
}
body[data-aos-delay="44450"][data-aos],
[data-aos][data-aos][data-aos-delay="44450"] {
  transition-delay: 44450ms;
}
body[data-aos-delay="44500"][data-aos],
[data-aos][data-aos][data-aos-delay="44500"] {
  transition-delay: 44500ms;
}
body[data-aos-delay="44550"][data-aos],
[data-aos][data-aos][data-aos-delay="44550"] {
  transition-delay: 44550ms;
}
body[data-aos-delay="44600"][data-aos],
[data-aos][data-aos][data-aos-delay="44600"] {
  transition-delay: 44600ms;
}
body[data-aos-delay="44650"][data-aos],
[data-aos][data-aos][data-aos-delay="44650"] {
  transition-delay: 44650ms;
}
body[data-aos-delay="44700"][data-aos],
[data-aos][data-aos][data-aos-delay="44700"] {
  transition-delay: 44700ms;
}
body[data-aos-delay="44750"][data-aos],
[data-aos][data-aos][data-aos-delay="44750"] {
  transition-delay: 44750ms;
}
body[data-aos-delay="44800"][data-aos],
[data-aos][data-aos][data-aos-delay="44800"] {
  transition-delay: 44800ms;
}
body[data-aos-delay="44850"][data-aos],
[data-aos][data-aos][data-aos-delay="44850"] {
  transition-delay: 44850ms;
}
body[data-aos-delay="44900"][data-aos],
[data-aos][data-aos][data-aos-delay="44900"] {
  transition-delay: 44900ms;
}
body[data-aos-delay="44950"][data-aos],
[data-aos][data-aos][data-aos-delay="44950"] {
  transition-delay: 44950ms;
}
body[data-aos-delay="45000"][data-aos],
[data-aos][data-aos][data-aos-delay="45000"] {
  transition-delay: 45000ms;
}
body[data-aos-delay="45050"][data-aos],
[data-aos][data-aos][data-aos-delay="45050"] {
  transition-delay: 45050ms;
}
body[data-aos-delay="45100"][data-aos],
[data-aos][data-aos][data-aos-delay="45100"] {
  transition-delay: 45100ms;
}
body[data-aos-delay="45150"][data-aos],
[data-aos][data-aos][data-aos-delay="45150"] {
  transition-delay: 45150ms;
}
body[data-aos-delay="45200"][data-aos],
[data-aos][data-aos][data-aos-delay="45200"] {
  transition-delay: 45200ms;
}
body[data-aos-delay="45250"][data-aos],
[data-aos][data-aos][data-aos-delay="45250"] {
  transition-delay: 45250ms;
}
body[data-aos-delay="45300"][data-aos],
[data-aos][data-aos][data-aos-delay="45300"] {
  transition-delay: 45300ms;
}
body[data-aos-delay="45350"][data-aos],
[data-aos][data-aos][data-aos-delay="45350"] {
  transition-delay: 45350ms;
}
body[data-aos-delay="45400"][data-aos],
[data-aos][data-aos][data-aos-delay="45400"] {
  transition-delay: 45400ms;
}
body[data-aos-delay="45450"][data-aos],
[data-aos][data-aos][data-aos-delay="45450"] {
  transition-delay: 45450ms;
}
body[data-aos-delay="45500"][data-aos],
[data-aos][data-aos][data-aos-delay="45500"] {
  transition-delay: 45500ms;
}
body[data-aos-delay="45550"][data-aos],
[data-aos][data-aos][data-aos-delay="45550"] {
  transition-delay: 45550ms;
}
body[data-aos-delay="45600"][data-aos],
[data-aos][data-aos][data-aos-delay="45600"] {
  transition-delay: 45600ms;
}
body[data-aos-delay="45650"][data-aos],
[data-aos][data-aos][data-aos-delay="45650"] {
  transition-delay: 45650ms;
}
body[data-aos-delay="45700"][data-aos],
[data-aos][data-aos][data-aos-delay="45700"] {
  transition-delay: 45700ms;
}
body[data-aos-delay="45750"][data-aos],
[data-aos][data-aos][data-aos-delay="45750"] {
  transition-delay: 45750ms;
}
body[data-aos-delay="45800"][data-aos],
[data-aos][data-aos][data-aos-delay="45800"] {
  transition-delay: 45800ms;
}
body[data-aos-delay="45850"][data-aos],
[data-aos][data-aos][data-aos-delay="45850"] {
  transition-delay: 45850ms;
}
body[data-aos-delay="45900"][data-aos],
[data-aos][data-aos][data-aos-delay="45900"] {
  transition-delay: 45900ms;
}
body[data-aos-delay="45950"][data-aos],
[data-aos][data-aos][data-aos-delay="45950"] {
  transition-delay: 45950ms;
}
body[data-aos-delay="46000"][data-aos],
[data-aos][data-aos][data-aos-delay="46000"] {
  transition-delay: 46000ms;
}
body[data-aos-delay="46050"][data-aos],
[data-aos][data-aos][data-aos-delay="46050"] {
  transition-delay: 46050ms;
}
body[data-aos-delay="46100"][data-aos],
[data-aos][data-aos][data-aos-delay="46100"] {
  transition-delay: 46100ms;
}
body[data-aos-delay="46150"][data-aos],
[data-aos][data-aos][data-aos-delay="46150"] {
  transition-delay: 46150ms;
}
body[data-aos-delay="46200"][data-aos],
[data-aos][data-aos][data-aos-delay="46200"] {
  transition-delay: 46200ms;
}
body[data-aos-delay="46250"][data-aos],
[data-aos][data-aos][data-aos-delay="46250"] {
  transition-delay: 46250ms;
}
body[data-aos-delay="46300"][data-aos],
[data-aos][data-aos][data-aos-delay="46300"] {
  transition-delay: 46300ms;
}
body[data-aos-delay="46350"][data-aos],
[data-aos][data-aos][data-aos-delay="46350"] {
  transition-delay: 46350ms;
}
body[data-aos-delay="46400"][data-aos],
[data-aos][data-aos][data-aos-delay="46400"] {
  transition-delay: 46400ms;
}
body[data-aos-delay="46450"][data-aos],
[data-aos][data-aos][data-aos-delay="46450"] {
  transition-delay: 46450ms;
}
body[data-aos-delay="46500"][data-aos],
[data-aos][data-aos][data-aos-delay="46500"] {
  transition-delay: 46500ms;
}
body[data-aos-delay="46550"][data-aos],
[data-aos][data-aos][data-aos-delay="46550"] {
  transition-delay: 46550ms;
}
body[data-aos-delay="46600"][data-aos],
[data-aos][data-aos][data-aos-delay="46600"] {
  transition-delay: 46600ms;
}
body[data-aos-delay="46650"][data-aos],
[data-aos][data-aos][data-aos-delay="46650"] {
  transition-delay: 46650ms;
}
body[data-aos-delay="46700"][data-aos],
[data-aos][data-aos][data-aos-delay="46700"] {
  transition-delay: 46700ms;
}
body[data-aos-delay="46750"][data-aos],
[data-aos][data-aos][data-aos-delay="46750"] {
  transition-delay: 46750ms;
}
body[data-aos-delay="46800"][data-aos],
[data-aos][data-aos][data-aos-delay="46800"] {
  transition-delay: 46800ms;
}
body[data-aos-delay="46850"][data-aos],
[data-aos][data-aos][data-aos-delay="46850"] {
  transition-delay: 46850ms;
}
body[data-aos-delay="46900"][data-aos],
[data-aos][data-aos][data-aos-delay="46900"] {
  transition-delay: 46900ms;
}
body[data-aos-delay="46950"][data-aos],
[data-aos][data-aos][data-aos-delay="46950"] {
  transition-delay: 46950ms;
}
body[data-aos-delay="47000"][data-aos],
[data-aos][data-aos][data-aos-delay="47000"] {
  transition-delay: 47000ms;
}
body[data-aos-delay="47050"][data-aos],
[data-aos][data-aos][data-aos-delay="47050"] {
  transition-delay: 47050ms;
}
body[data-aos-delay="47100"][data-aos],
[data-aos][data-aos][data-aos-delay="47100"] {
  transition-delay: 47100ms;
}
body[data-aos-delay="47150"][data-aos],
[data-aos][data-aos][data-aos-delay="47150"] {
  transition-delay: 47150ms;
}
body[data-aos-delay="47200"][data-aos],
[data-aos][data-aos][data-aos-delay="47200"] {
  transition-delay: 47200ms;
}
body[data-aos-delay="47250"][data-aos],
[data-aos][data-aos][data-aos-delay="47250"] {
  transition-delay: 47250ms;
}
body[data-aos-delay="47300"][data-aos],
[data-aos][data-aos][data-aos-delay="47300"] {
  transition-delay: 47300ms;
}
body[data-aos-delay="47350"][data-aos],
[data-aos][data-aos][data-aos-delay="47350"] {
  transition-delay: 47350ms;
}
body[data-aos-delay="47400"][data-aos],
[data-aos][data-aos][data-aos-delay="47400"] {
  transition-delay: 47400ms;
}
body[data-aos-delay="47450"][data-aos],
[data-aos][data-aos][data-aos-delay="47450"] {
  transition-delay: 47450ms;
}
body[data-aos-delay="47500"][data-aos],
[data-aos][data-aos][data-aos-delay="47500"] {
  transition-delay: 47500ms;
}
body[data-aos-delay="47550"][data-aos],
[data-aos][data-aos][data-aos-delay="47550"] {
  transition-delay: 47550ms;
}
body[data-aos-delay="47600"][data-aos],
[data-aos][data-aos][data-aos-delay="47600"] {
  transition-delay: 47600ms;
}
body[data-aos-delay="47650"][data-aos],
[data-aos][data-aos][data-aos-delay="47650"] {
  transition-delay: 47650ms;
}
body[data-aos-delay="47700"][data-aos],
[data-aos][data-aos][data-aos-delay="47700"] {
  transition-delay: 47700ms;
}
body[data-aos-delay="47750"][data-aos],
[data-aos][data-aos][data-aos-delay="47750"] {
  transition-delay: 47750ms;
}
body[data-aos-delay="47800"][data-aos],
[data-aos][data-aos][data-aos-delay="47800"] {
  transition-delay: 47800ms;
}
body[data-aos-delay="47850"][data-aos],
[data-aos][data-aos][data-aos-delay="47850"] {
  transition-delay: 47850ms;
}
body[data-aos-delay="47900"][data-aos],
[data-aos][data-aos][data-aos-delay="47900"] {
  transition-delay: 47900ms;
}
body[data-aos-delay="47950"][data-aos],
[data-aos][data-aos][data-aos-delay="47950"] {
  transition-delay: 47950ms;
}
body[data-aos-delay="48000"][data-aos],
[data-aos][data-aos][data-aos-delay="48000"] {
  transition-delay: 48000ms;
}
body[data-aos-delay="48050"][data-aos],
[data-aos][data-aos][data-aos-delay="48050"] {
  transition-delay: 48050ms;
}
body[data-aos-delay="48100"][data-aos],
[data-aos][data-aos][data-aos-delay="48100"] {
  transition-delay: 48100ms;
}
body[data-aos-delay="48150"][data-aos],
[data-aos][data-aos][data-aos-delay="48150"] {
  transition-delay: 48150ms;
}
body[data-aos-delay="48200"][data-aos],
[data-aos][data-aos][data-aos-delay="48200"] {
  transition-delay: 48200ms;
}
body[data-aos-delay="48250"][data-aos],
[data-aos][data-aos][data-aos-delay="48250"] {
  transition-delay: 48250ms;
}
body[data-aos-delay="48300"][data-aos],
[data-aos][data-aos][data-aos-delay="48300"] {
  transition-delay: 48300ms;
}
body[data-aos-delay="48350"][data-aos],
[data-aos][data-aos][data-aos-delay="48350"] {
  transition-delay: 48350ms;
}
body[data-aos-delay="48400"][data-aos],
[data-aos][data-aos][data-aos-delay="48400"] {
  transition-delay: 48400ms;
}
body[data-aos-delay="48450"][data-aos],
[data-aos][data-aos][data-aos-delay="48450"] {
  transition-delay: 48450ms;
}
body[data-aos-delay="48500"][data-aos],
[data-aos][data-aos][data-aos-delay="48500"] {
  transition-delay: 48500ms;
}
body[data-aos-delay="48550"][data-aos],
[data-aos][data-aos][data-aos-delay="48550"] {
  transition-delay: 48550ms;
}
body[data-aos-delay="48600"][data-aos],
[data-aos][data-aos][data-aos-delay="48600"] {
  transition-delay: 48600ms;
}
body[data-aos-delay="48650"][data-aos],
[data-aos][data-aos][data-aos-delay="48650"] {
  transition-delay: 48650ms;
}
body[data-aos-delay="48700"][data-aos],
[data-aos][data-aos][data-aos-delay="48700"] {
  transition-delay: 48700ms;
}
body[data-aos-delay="48750"][data-aos],
[data-aos][data-aos][data-aos-delay="48750"] {
  transition-delay: 48750ms;
}
body[data-aos-delay="48800"][data-aos],
[data-aos][data-aos][data-aos-delay="48800"] {
  transition-delay: 48800ms;
}
body[data-aos-delay="48850"][data-aos],
[data-aos][data-aos][data-aos-delay="48850"] {
  transition-delay: 48850ms;
}
body[data-aos-delay="48900"][data-aos],
[data-aos][data-aos][data-aos-delay="48900"] {
  transition-delay: 48900ms;
}
body[data-aos-delay="48950"][data-aos],
[data-aos][data-aos][data-aos-delay="48950"] {
  transition-delay: 48950ms;
}
body[data-aos-delay="49000"][data-aos],
[data-aos][data-aos][data-aos-delay="49000"] {
  transition-delay: 49000ms;
}
body[data-aos-delay="49050"][data-aos],
[data-aos][data-aos][data-aos-delay="49050"] {
  transition-delay: 49050ms;
}
body[data-aos-delay="49100"][data-aos],
[data-aos][data-aos][data-aos-delay="49100"] {
  transition-delay: 49100ms;
}
body[data-aos-delay="49150"][data-aos],
[data-aos][data-aos][data-aos-delay="49150"] {
  transition-delay: 49150ms;
}
body[data-aos-delay="49200"][data-aos],
[data-aos][data-aos][data-aos-delay="49200"] {
  transition-delay: 49200ms;
}
body[data-aos-delay="49250"][data-aos],
[data-aos][data-aos][data-aos-delay="49250"] {
  transition-delay: 49250ms;
}
body[data-aos-delay="49300"][data-aos],
[data-aos][data-aos][data-aos-delay="49300"] {
  transition-delay: 49300ms;
}
body[data-aos-delay="49350"][data-aos],
[data-aos][data-aos][data-aos-delay="49350"] {
  transition-delay: 49350ms;
}
body[data-aos-delay="49400"][data-aos],
[data-aos][data-aos][data-aos-delay="49400"] {
  transition-delay: 49400ms;
}
body[data-aos-delay="49450"][data-aos],
[data-aos][data-aos][data-aos-delay="49450"] {
  transition-delay: 49450ms;
}
body[data-aos-delay="49500"][data-aos],
[data-aos][data-aos][data-aos-delay="49500"] {
  transition-delay: 49500ms;
}
body[data-aos-delay="49550"][data-aos],
[data-aos][data-aos][data-aos-delay="49550"] {
  transition-delay: 49550ms;
}
body[data-aos-delay="49600"][data-aos],
[data-aos][data-aos][data-aos-delay="49600"] {
  transition-delay: 49600ms;
}
body[data-aos-delay="49650"][data-aos],
[data-aos][data-aos][data-aos-delay="49650"] {
  transition-delay: 49650ms;
}
body[data-aos-delay="49700"][data-aos],
[data-aos][data-aos][data-aos-delay="49700"] {
  transition-delay: 49700ms;
}
body[data-aos-delay="49750"][data-aos],
[data-aos][data-aos][data-aos-delay="49750"] {
  transition-delay: 49750ms;
}
body[data-aos-delay="49800"][data-aos],
[data-aos][data-aos][data-aos-delay="49800"] {
  transition-delay: 49800ms;
}
body[data-aos-delay="49850"][data-aos],
[data-aos][data-aos][data-aos-delay="49850"] {
  transition-delay: 49850ms;
}
body[data-aos-delay="49900"][data-aos],
[data-aos][data-aos][data-aos-delay="49900"] {
  transition-delay: 49900ms;
}
body[data-aos-delay="49950"][data-aos],
[data-aos][data-aos][data-aos-delay="49950"] {
  transition-delay: 49950ms;
}
body[data-aos-delay="50000"][data-aos],
[data-aos][data-aos][data-aos-delay="50000"] {
  transition-delay: 50000ms;
}
body[data-aos-delay="50050"][data-aos],
[data-aos][data-aos][data-aos-delay="50050"] {
  transition-delay: 50050ms;
}
body[data-aos-delay="50100"][data-aos],
[data-aos][data-aos][data-aos-delay="50100"] {
  transition-delay: 50100ms;
}
body[data-aos-delay="50150"][data-aos],
[data-aos][data-aos][data-aos-delay="50150"] {
  transition-delay: 50150ms;
}
body[data-aos-delay="50200"][data-aos],
[data-aos][data-aos][data-aos-delay="50200"] {
  transition-delay: 50200ms;
}
body[data-aos-delay="50250"][data-aos],
[data-aos][data-aos][data-aos-delay="50250"] {
  transition-delay: 50250ms;
}
body[data-aos-delay="50300"][data-aos],
[data-aos][data-aos][data-aos-delay="50300"] {
  transition-delay: 50300ms;
}
body[data-aos-delay="50350"][data-aos],
[data-aos][data-aos][data-aos-delay="50350"] {
  transition-delay: 50350ms;
}
body[data-aos-delay="50400"][data-aos],
[data-aos][data-aos][data-aos-delay="50400"] {
  transition-delay: 50400ms;
}
body[data-aos-delay="50450"][data-aos],
[data-aos][data-aos][data-aos-delay="50450"] {
  transition-delay: 50450ms;
}
body[data-aos-delay="50500"][data-aos],
[data-aos][data-aos][data-aos-delay="50500"] {
  transition-delay: 50500ms;
}
body[data-aos-delay="50550"][data-aos],
[data-aos][data-aos][data-aos-delay="50550"] {
  transition-delay: 50550ms;
}
body[data-aos-delay="50600"][data-aos],
[data-aos][data-aos][data-aos-delay="50600"] {
  transition-delay: 50600ms;
}
body[data-aos-delay="50650"][data-aos],
[data-aos][data-aos][data-aos-delay="50650"] {
  transition-delay: 50650ms;
}
body[data-aos-delay="50700"][data-aos],
[data-aos][data-aos][data-aos-delay="50700"] {
  transition-delay: 50700ms;
}
body[data-aos-delay="50750"][data-aos],
[data-aos][data-aos][data-aos-delay="50750"] {
  transition-delay: 50750ms;
}
body[data-aos-delay="50800"][data-aos],
[data-aos][data-aos][data-aos-delay="50800"] {
  transition-delay: 50800ms;
}
body[data-aos-delay="50850"][data-aos],
[data-aos][data-aos][data-aos-delay="50850"] {
  transition-delay: 50850ms;
}
body[data-aos-delay="50900"][data-aos],
[data-aos][data-aos][data-aos-delay="50900"] {
  transition-delay: 50900ms;
}
body[data-aos-delay="50950"][data-aos],
[data-aos][data-aos][data-aos-delay="50950"] {
  transition-delay: 50950ms;
}
body[data-aos-delay="51000"][data-aos],
[data-aos][data-aos][data-aos-delay="51000"] {
  transition-delay: 51000ms;
}
body[data-aos-delay="51050"][data-aos],
[data-aos][data-aos][data-aos-delay="51050"] {
  transition-delay: 51050ms;
}
body[data-aos-delay="51100"][data-aos],
[data-aos][data-aos][data-aos-delay="51100"] {
  transition-delay: 51100ms;
}
body[data-aos-delay="51150"][data-aos],
[data-aos][data-aos][data-aos-delay="51150"] {
  transition-delay: 51150ms;
}
body[data-aos-delay="51200"][data-aos],
[data-aos][data-aos][data-aos-delay="51200"] {
  transition-delay: 51200ms;
}
body[data-aos-delay="51250"][data-aos],
[data-aos][data-aos][data-aos-delay="51250"] {
  transition-delay: 51250ms;
}
body[data-aos-delay="51300"][data-aos],
[data-aos][data-aos][data-aos-delay="51300"] {
  transition-delay: 51300ms;
}
body[data-aos-delay="51350"][data-aos],
[data-aos][data-aos][data-aos-delay="51350"] {
  transition-delay: 51350ms;
}
body[data-aos-delay="51400"][data-aos],
[data-aos][data-aos][data-aos-delay="51400"] {
  transition-delay: 51400ms;
}
body[data-aos-delay="51450"][data-aos],
[data-aos][data-aos][data-aos-delay="51450"] {
  transition-delay: 51450ms;
}
body[data-aos-delay="51500"][data-aos],
[data-aos][data-aos][data-aos-delay="51500"] {
  transition-delay: 51500ms;
}
body[data-aos-delay="51550"][data-aos],
[data-aos][data-aos][data-aos-delay="51550"] {
  transition-delay: 51550ms;
}
body[data-aos-delay="51600"][data-aos],
[data-aos][data-aos][data-aos-delay="51600"] {
  transition-delay: 51600ms;
}
body[data-aos-delay="51650"][data-aos],
[data-aos][data-aos][data-aos-delay="51650"] {
  transition-delay: 51650ms;
}
body[data-aos-delay="51700"][data-aos],
[data-aos][data-aos][data-aos-delay="51700"] {
  transition-delay: 51700ms;
}
body[data-aos-delay="51750"][data-aos],
[data-aos][data-aos][data-aos-delay="51750"] {
  transition-delay: 51750ms;
}
body[data-aos-delay="51800"][data-aos],
[data-aos][data-aos][data-aos-delay="51800"] {
  transition-delay: 51800ms;
}
body[data-aos-delay="51850"][data-aos],
[data-aos][data-aos][data-aos-delay="51850"] {
  transition-delay: 51850ms;
}
body[data-aos-delay="51900"][data-aos],
[data-aos][data-aos][data-aos-delay="51900"] {
  transition-delay: 51900ms;
}
body[data-aos-delay="51950"][data-aos],
[data-aos][data-aos][data-aos-delay="51950"] {
  transition-delay: 51950ms;
}
body[data-aos-delay="52000"][data-aos],
[data-aos][data-aos][data-aos-delay="52000"] {
  transition-delay: 52000ms;
}
body[data-aos-delay="52050"][data-aos],
[data-aos][data-aos][data-aos-delay="52050"] {
  transition-delay: 52050ms;
}
body[data-aos-delay="52100"][data-aos],
[data-aos][data-aos][data-aos-delay="52100"] {
  transition-delay: 52100ms;
}
body[data-aos-delay="52150"][data-aos],
[data-aos][data-aos][data-aos-delay="52150"] {
  transition-delay: 52150ms;
}
body[data-aos-delay="52200"][data-aos],
[data-aos][data-aos][data-aos-delay="52200"] {
  transition-delay: 52200ms;
}
body[data-aos-delay="52250"][data-aos],
[data-aos][data-aos][data-aos-delay="52250"] {
  transition-delay: 52250ms;
}
body[data-aos-delay="52300"][data-aos],
[data-aos][data-aos][data-aos-delay="52300"] {
  transition-delay: 52300ms;
}
body[data-aos-delay="52350"][data-aos],
[data-aos][data-aos][data-aos-delay="52350"] {
  transition-delay: 52350ms;
}
body[data-aos-delay="52400"][data-aos],
[data-aos][data-aos][data-aos-delay="52400"] {
  transition-delay: 52400ms;
}
body[data-aos-delay="52450"][data-aos],
[data-aos][data-aos][data-aos-delay="52450"] {
  transition-delay: 52450ms;
}
body[data-aos-delay="52500"][data-aos],
[data-aos][data-aos][data-aos-delay="52500"] {
  transition-delay: 52500ms;
}
body[data-aos-delay="52550"][data-aos],
[data-aos][data-aos][data-aos-delay="52550"] {
  transition-delay: 52550ms;
}
body[data-aos-delay="52600"][data-aos],
[data-aos][data-aos][data-aos-delay="52600"] {
  transition-delay: 52600ms;
}
body[data-aos-delay="52650"][data-aos],
[data-aos][data-aos][data-aos-delay="52650"] {
  transition-delay: 52650ms;
}
body[data-aos-delay="52700"][data-aos],
[data-aos][data-aos][data-aos-delay="52700"] {
  transition-delay: 52700ms;
}
body[data-aos-delay="52750"][data-aos],
[data-aos][data-aos][data-aos-delay="52750"] {
  transition-delay: 52750ms;
}
body[data-aos-delay="52800"][data-aos],
[data-aos][data-aos][data-aos-delay="52800"] {
  transition-delay: 52800ms;
}
body[data-aos-delay="52850"][data-aos],
[data-aos][data-aos][data-aos-delay="52850"] {
  transition-delay: 52850ms;
}
body[data-aos-delay="52900"][data-aos],
[data-aos][data-aos][data-aos-delay="52900"] {
  transition-delay: 52900ms;
}
body[data-aos-delay="52950"][data-aos],
[data-aos][data-aos][data-aos-delay="52950"] {
  transition-delay: 52950ms;
}
